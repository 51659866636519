import * as uuid from 'uuid';
import { isUUID } from '../utilities';

export default class Location {

    static defaultValues = {
        archived: false,
    };

    id: string;
    name: string;
    locationTemplate: string;
    archived: boolean;
    site: string;

    static getInstanceFromObject(initializationData: any) {

        if (typeof initializationData !== 'object') {
            throw new Error('The initialization data must be an object');
        }

        // Seed all the default values into the object if they don't exist
        for (const defaultKey in Location.defaultValues) {
            if (!initializationData.hasOwnProperty(defaultKey)) {
                initializationData[defaultKey] = Location.defaultValues[defaultKey];
            }
        }

        // Begin validation

        // ID validation
        if (initializationData.hasOwnProperty('id')) {

            if (!isUUID(initializationData.id)) {
                throw new Error('Invalid ID');
            }
        }

        // Name validation
        if (!initializationData.hasOwnProperty('name')) {
            throw new Error('The name is required');
        }

        if (typeof initializationData.name !== 'string' || initializationData.name.trim().length === 0) {
            throw new Error('Invalid name');
        }

        // Type validation
        if (!initializationData.hasOwnProperty('locationTemplate') || typeof initializationData.locationTemplate === 'undefined' ||
            initializationData.locationTemplate === null) {
            throw new Error('The location template is required');
        }

        if (!isUUID(initializationData.locationTemplate)) {
            throw new Error('Invalid location template ID');
        }

        // Site validation
        if (!initializationData.hasOwnProperty('site')) {
            throw new Error('The site is required');
        }

        if (typeof initializationData.site !== 'string' || initializationData.site.trim().length === 0 || !isUUID(initializationData.site)) {
            throw new Error('Invalid site');
        }
        

        // End validation



        // Instantiation
        const newInstance = new Location();

        if ('id' in initializationData) {
            newInstance.id = initializationData.id;
        } else {
            newInstance.id = uuid.v4();
        }

        newInstance.archived = initializationData.archived;

        newInstance.name = initializationData.name;
        newInstance.locationTemplate = initializationData.locationTemplate;
        newInstance.site = initializationData.site;

        return newInstance;
    }

    convertToPlainObject() {
        return {
            ...this as Location,
        };
    }

    update(updateObject: any) {
        const { id, ...otherParams } = updateObject;

        for (const classKey in this) {
            if (otherParams.hasOwnProperty(classKey)) {
                this[classKey] = otherParams[classKey];
            }
        }
    }
}
