import { Injectable } from '@angular/core';
import { StorageWrapper } from 'src/shared/storage/storage-wrapper';
import ToDo, { ToDoEventType } from 'src/shared/models/to-do';
import { TO_DOS } from 'src/shared/mocks/to-do';
import { AsyncLocalStorage } from 'src/shared/storage/storable';
import { AuthenticationService } from 'src/shared/services/authentication.service';
import { UrlService } from 'src/shared/services/url.service';
import { HttpClient } from '@angular/common/http';
import { CustomAttributeValues } from 'src/shared/models/custom-attribute';
import { ToDoTemplateService } from './to-do-template.service';
import moment from 'moment';
import { Answer, ActionSnapshot, UserActionReport } from 'src/shared/models/question';
import { StateSnapshot } from 'src/shared/models/state';

@Injectable({
    providedIn: 'root'
})
export class ToDoService {
    storage: AsyncLocalStorage;
    storageWrapper: StorageWrapper<ToDo>;

    constructor(
        authenticationService: AuthenticationService,
        private toDoTemplateService: ToDoTemplateService,
        urlService: UrlService,
        http: HttpClient
    ) {
        this.storage = new AsyncLocalStorage();
        this.storageWrapper = new StorageWrapper(
            'to_do_entries',
            'to-dos',
            ToDo.getInstanceFromObject,
            this.storage,
            authenticationService,
            urlService,
            http,
            TO_DOS
        );
    }

    async getToDoEntries(force = false) {
        return this.storageWrapper.getEntries(force);
    }

    getToDoEntriesObservable() {
        return this.storageWrapper.getEntriesObservable();
    }

    async addToDo(newToDo: ToDo) {
        return this.storageWrapper.addEntry(newToDo);
    }

    async updateToDo(assetTemplate: ToDo) {
        return this.storageWrapper.updateEntry(assetTemplate);
    }

    async deleteToDo(deletedToDoId: string) {
        return this.storageWrapper.softDeleteEntry(deletedToDoId);
    }

    async getToDoById(id: string) {
        return this.storageWrapper.getEntryById(id);
    }

    getToDoEntryObservable(id: string) {
        return this.storageWrapper.getEntryObservable(id);
    }

    async pushNewAction(toDoId: string, action: ActionSnapshot) {
        const toDo = await this.getToDoById(toDoId);
        const updatedToDo = ToDo.getInstanceFromObject({
            ...toDo,
        });

        updatedToDo.events.push({
            type: ToDoEventType.TRIGGERED_ACTION,
            payload: action,
        });

        this.updateToDo(updatedToDo);
    }

    async pushNewUserActionPrompt(toDoId: string, actionPrompt: UserActionReport) {
        const toDo = await this.getToDoById(toDoId);
        const updatedToDo = ToDo.getInstanceFromObject({
            ...toDo,
        });

        updatedToDo.events.push({
            type: ToDoEventType.USER_ACTION_PROMPT,
            payload: actionPrompt,
        });

        this.updateToDo(updatedToDo);
    }

    async pushNewAnswer(toDoId: string, answer: Answer) {
        const toDo = await this.getToDoById(toDoId);
        const updatedToDo = ToDo.getInstanceFromObject({
            ...toDo,
        });

        updatedToDo.answers.push(answer);

        this.updateToDo(updatedToDo);
    }

    async pushNewState(toDoId: string, stateId: string, customValuesForState?: CustomAttributeValues) {
        const toDo = await this.getToDoById(toDoId);
        const updatedToDo = ToDo.getInstanceFromObject({
            ...toDo,
        });

        const toDoTemplate = await this.toDoTemplateService.getToDoTemplateById(toDo.toDoTemplate);
        const newState = toDoTemplate.states.find(state => state.id === stateId);

        if (newState.status) {
            updatedToDo.status = newState.status;
        }

        const stateSnapshot: StateSnapshot = {
            stateId,
            transitionTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
            customAttributeValues: customValuesForState || {},
        };

        updatedToDo.currentStateId = stateId;

        updatedToDo.events.push({
            type: ToDoEventType.STATE,
            payload: stateSnapshot,
        });

        this.updateToDo(updatedToDo);
    }

    async refreshEntries() {
        return this.storageWrapper.refreshEntries();
    }

    async pushDataToServer() {
        return this.storageWrapper.pushDataToServer();
    }
}
