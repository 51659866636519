import { Injectable } from '@angular/core';
import { StorageWrapper } from 'src/shared/storage/storage-wrapper';
import ToDoTemplate from 'src/shared/models/to-do-template';
import { TO_DO_TEMPLATES } from 'src/shared/mocks/to-do-template';
import { AsyncLocalStorage } from 'src/shared/storage/storable';
import { AuthenticationService } from 'src/shared/services/authentication.service';
import { UrlService } from 'src/shared/services/url.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ToDoTemplateService {
  storage: AsyncLocalStorage;
  storageWrapper: StorageWrapper<ToDoTemplate>;

  constructor(
      authenticationService: AuthenticationService,
      urlService: UrlService,
      http: HttpClient
  ) {
      this.storage = new AsyncLocalStorage();
      this.storageWrapper = new StorageWrapper(
          'to_do_template_entries',
          'to-do-templates',
          ToDoTemplate.getInstanceFromObject,
          this.storage,
          authenticationService,
          urlService,
          http,
          TO_DO_TEMPLATES
      );
  }

  async getToDoTemplateEntries(force = false) {
      return this.storageWrapper.getEntries(force);
  }

  getToDoTemplateEntriesObservable() {
      return this.storageWrapper.getEntriesObservable();
  }

  async addToDoTemplate(newToDo: ToDoTemplate) {
      return this.storageWrapper.addEntry(newToDo);
  }

  async updateToDoTemplate(toDoTemplate: ToDoTemplate) {
      return this.storageWrapper.updateEntry(toDoTemplate);
  }

  async deleteToDoTemplate(deletedToDoId: string) {
      return this.storageWrapper.softDeleteEntry(deletedToDoId);
  }

  async getToDoTemplateById(id: string) {
      return this.storageWrapper.getEntryById(id);
  }

  getToDoTemplateEntryObservable(id: string) {
      return this.storageWrapper.getEntryObservable(id);
  }

  async refreshEntries() {
      return this.storageWrapper.refreshEntries();
  }

  async pushDataToServer() {
      return this.storageWrapper.pushDataToServer();
  }
}
