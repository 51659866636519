import { Injectable } from '@angular/core';
import { StorageWrapper } from 'src/shared/storage/storage-wrapper';
import Site from 'src/shared/models/site';
import { SITES } from 'src/shared/mocks/site';
import { AsyncLocalStorage } from 'src/shared/storage/storable';
import { AuthenticationService } from 'src/shared/services/authentication.service';
import { UrlService } from 'src/shared/services/url.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SiteService {
    storage: AsyncLocalStorage;
    storageWrapper: StorageWrapper<Site>;

    constructor(
        authenticationService: AuthenticationService,
        urlService: UrlService,
        http: HttpClient
    ) {
        this.storage = new AsyncLocalStorage();
        this.storageWrapper = new StorageWrapper(
            'site_entries',
            'sites',
            Site.getInstanceFromObject,
            this.storage,
            authenticationService,
            urlService,
            http,
            SITES
        );
    }

    async getSiteEntries(force = false) {
        return this.storageWrapper.getEntries(force);
    }

    getSiteEntriesObservable() {
        return this.storageWrapper.getEntriesObservable();
    }

    async addSite(newSite: Site) {
        return this.storageWrapper.addEntry(newSite);
    }

    async updateSite(site: Site) {
        return this.storageWrapper.updateEntry(site);
    }

    async deleteSite(deletedSiteId: string) {
        return this.storageWrapper.softDeleteEntry(deletedSiteId);
    }

    async getSiteById(id: string) {
        return this.storageWrapper.getEntryById(id);
    }

    async getSiteByName(name: string) {
        const assets = await this.getSiteEntries();
        return assets.find(site => site.name === name);
    }

    getSiteEntryObservable(id: string) {
        return this.storageWrapper.getEntryObservable(id);
    }

    async refreshEntries() {
        return this.storageWrapper.refreshEntries();
    }

    async pushDataToServer() {
        return this.storageWrapper.pushDataToServer();
    }
}
