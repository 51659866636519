import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AssetService } from './services/asset.service';
import { EmployeeService } from './services/employee.service';
import { LocationService } from './services/location.service';
import { SiteService } from './services/site.service';
import { ToDoTemplateService } from './services/to-do-template.service';
import { ToDoService } from './services/to-do.service';
import { AuthenticationService } from 'src/shared/services/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    onlineEvent: Observable<Event>;
  
    subscriptions: Subscription[] = [];
    
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private assetService: AssetService,
        private employeeService: EmployeeService,
        private locationService: LocationService,
        private siteService: SiteService, 
        private toDoTemplateService: ToDoTemplateService,
        private toDoService: ToDoService,
        private authenticationService: AuthenticationService,
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }
  
    async fetchAllData() {
      await this.toDoTemplateService.refreshEntries();
      await this.assetService.refreshEntries();
      await this.employeeService.refreshEntries();
      await this.siteService.refreshEntries();
      await this.locationService.refreshEntries();
      await this.toDoService.refreshEntries();
    }

    async pushAllData() {
      await this.toDoTemplateService.pushDataToServer();
      await this.assetService.pushDataToServer();
      await this.employeeService.pushDataToServer();
      await this.siteService.pushDataToServer();
      await this.locationService.pushDataToServer();
      await this.toDoService.pushDataToServer();
    }
  
    ngOnInit(): void {
      this.onlineEvent = fromEvent(window, 'online');
  
      this.subscriptions.push(this.onlineEvent.subscribe(this.pushAllData));
      this.authenticationService.getAuthenticationObservable().subscribe(token => {
        if (!!token) {
          // The user is online
          this.fetchAllData();
        }
      })
    }
  
    ngOnDestroy(): void {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
