import { QuestionType, Unit, Question, Operator, ResultType } from "../models/question";

export const UPS_QUESTIONS: Array<Question> = [
    {
        id: "758321d6-0682-442b-bd0a-3c744a679ea6",
        type: QuestionType.BOOLEAN,
        isRequired: false,
        title: "Is UPS Clean?",
        successLabel: "Clean",
        failureLabel: "Not clean",
        nesting: {
            pass: {
                questions: [
                    {
                        id: "b50dd98e-be61-4d9d-829a-a485f62485e9",
                        type: QuestionType.IMAGE,
                        isRequired: false,
                    }
                ],
                actions: [],
                userActionPrompts: [],
            },
            fail: {
                questions: [
                    {
                        id: "a47f42ad-73a9-48b0-a9d9-ed4d5644fdcb",
                        type: QuestionType.TEXT,
                        isRequired: false,
                        placeholder: "What's wrong?",
                        rules: [],
                        nesting: {
                            pass: {
                                questions: [],
                                actions: [],
                                userActionPrompts: [],
                            },
                            fail: {
                                questions: [],
                                actions: [],
                                userActionPrompts: [],
                            },
                            flag: {
                                questions: [],
                                actions: [],
                                userActionPrompts: [],
                            }
                        },
                    }
                ],
                actions: [],
                userActionPrompts: [],
            },
            flag: {
                questions: [],
                actions: [],
                userActionPrompts: [],
            },
        },
    },
    {
        id: "920cca9a-efe0-48f7-94c1-5ba116ece85b",
        type: QuestionType.NUMBER,
        isRequired: false,
        title: "Room Temperature",
        successLabel: "21 - 24",
        placeholder: "Room Temperature",
        unit: Unit.CELCIUS,
        rules: [
            {
                id: "420c983c-53d7-4114-bb0d-fe842c9c796f",
                condition: {
                    operator: Operator.LESSER_THAN,
                    leftOperand: "",
                    rightOperand: "21"
                },
                result: ResultType.FAIL,
            },
            {
                id: "858a266b-f550-4f17-8254-4ea6efc0ee45",
                condition: {
                    operator: Operator.GREATER_THAN,
                    leftOperand: "",
                    rightOperand: "24"
                },
                result: ResultType.FAIL,
            }
        ],
        nesting: {
            pass: {
                questions: [],
                actions: [],
                userActionPrompts: [],
            },
            fail: {
                questions: [
                    {
                        id: "fb65f143-48f0-4e55-acff-daecb49b72ee",
                        type: QuestionType.BOOLEAN,
                        isRequired: false,
                        title: "Condition of Exhaust Fan",
                        successLabel: "Normal",
                        failureLabel: "Defective",
                        nesting: {
                            pass: {
                                questions: [],
                                actions: [],
                                userActionPrompts: [],
                            },
                            fail: {
                                questions: [
                                    {
                                        id: "1d3c6312-0a86-445c-8c95-13e2bec696b8",
                                        type: QuestionType.IMAGE,
                                        isRequired: false,
                                    }
                                ],
                                actions: [],
                                userActionPrompts: [],
                            },
                            flag: {
                                questions: [],
                                actions: [],
                                userActionPrompts: [],
                            }
                        },
                    }
                ],
                actions: [],
                userActionPrompts: [],
            },
            flag: {
                questions: [],
                actions: [],
                userActionPrompts: [],
            }
        },
    }
]