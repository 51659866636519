import Asset from '../models/asset';
import { ASSET_TEMPLATES } from './asset-template';
import { LOCATIONS } from './location';

export const ASSETS: Array<Asset> = [Asset.getInstanceFromObject({
    id: '1ab12eff-3814-4da7-9c55-6a6330bf9c46',
    name: 'UPS one',
    readableId: '1000',
    assetTemplate: ASSET_TEMPLATES[0].id,
    location: LOCATIONS[0].id,
    customAttributeValues: {},
})];
