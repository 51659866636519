import { Question, QuestionType, ChecklistAction, TriggerTaskAction, UserActionPrompt, getQuestionFromPlainObject } from './question';
import State, { ActionType } from './state';
import CustomAttribute from './custom-attribute';
import { isUUID } from '../utilities';
import * as uuid from 'uuid';
import { ToDoStatus } from './to-do';

export enum ToDoTemplateBinding {
    ASSET = 'A',
    LOCATION = 'L',
    NONE = 'N',
}

export default class ToDoTemplate {

    static defaultValues = {
        archived: false,
        binding: ToDoTemplateBinding.ASSET,
        states: [],
        initialStateId: '',
        customAttributes: [],
        questions: [],
    };
    
    id: string;
    archived: boolean;
    name: string;
    binding: ToDoTemplateBinding;
    assetTemplate: string;
    department: string;
    site: string;
    states: Array<State>;
    initialStateId: string;
    customAttributes: Array<CustomAttribute>;
    
    questions: Array<Question>;

    static getInstanceFromObject(initializationData: any) {

        if (typeof initializationData !== 'object') {
            throw new Error('The initialization data must be an object');
        }

        // Seed all the default values into the object if they don't exist
        for (const defaultKey in ToDoTemplate.defaultValues) {
            if (!initializationData.hasOwnProperty(defaultKey)) {
                initializationData[defaultKey] = ToDoTemplate.defaultValues[defaultKey];
            }
        }

        // Begin validation

        // ID validation
        if (initializationData.hasOwnProperty('id')) {

            if (!isUUID(initializationData.id)) {
                throw new Error('Invalid ID');
            }
        }

        // Name validation
        if (!initializationData.hasOwnProperty('name')) {
            throw new Error('The name is required');
        }

        if (typeof initializationData.name !== 'string' || initializationData.name.trim().length === 0) {
            throw new Error('Invalid name');
        }

        // Department validation
        if (initializationData.hasOwnProperty('department') && !!initializationData.department) {

            if (typeof initializationData.department !== 'string' || initializationData.department.trim().length === 0 || !isUUID(initializationData.department)) {
                throw new Error('Invalid department');
            }
        }

        // Binding validation
        const bindings = Object.keys(ToDoTemplateBinding).map(binding => ToDoTemplateBinding[binding]);

        if (!bindings.includes(initializationData.binding)) {
            throw new Error('Invalid binding');
        }

        if (initializationData.binding === ToDoTemplateBinding.ASSET) {
            if (!initializationData.hasOwnProperty('assetTemplate') || !initializationData.assetTemplate) {
                throw new Error('The asset template is required');
            }

            if (typeof initializationData.assetTemplate !== 'string' || initializationData.assetTemplate.trim().length === 0 || !isUUID(initializationData.assetTemplate)) {
                throw new Error('Invalid asset template');
            }
        }

        // States validation
        if (!Array.isArray(initializationData.states)) {
            throw new Error('States must be an array');
        }

        for (const state of initializationData.states) {

            if (!state.hasOwnProperty('id')) {
                throw new Error('The state must have a value for the ID');
            }

            if (!isUUID(state.id)) {
                throw new Error('The state ID is invalid');
            }

            if (!state.hasOwnProperty('name')) {
                throw new Error('The state must have a value for the name');
            }

            if (typeof state.name !== 'string' || state.name.trim().length === 0) {
                throw new Error('The state name is invalid');
            }

            if (state.hasOwnProperty('status') && typeof state.status !== 'undefined' && state.status !== null) {
                const statuses = Object.keys(ToDoStatus).map(status => ToDoStatus[status]);

                if (!statuses.includes(state.status)) {
                    throw new Error('The state status is invalid');
                }
            }

            if (!state.hasOwnProperty('canDispatcherExecute')) {
                throw new Error('The state must have a value for the can dispatcher execute field');
            }

            if (typeof state.canDispatcherExecute !== 'boolean') {
                throw new Error('The state can-dispatcher-execute is invalid');
            }

            if (!state.hasOwnProperty('canTechnicianExecute')) {
                throw new Error('The state must have a value for the can field technician execute field');
            }

            if (typeof state.canTechnicianExecute !== 'boolean') {
                throw new Error('The state can-field-technician-execute is invalid');
            }

            // State actions validation
            if (!Array.isArray(state.actions)) {
                throw new Error('Custom Attributes options must be an array');
            }

            for (const action of state.actions) {

                if (!action.hasOwnProperty('name')) {
                    throw new Error('The action must have a value for the name');
                }

                if (typeof action.name !== 'string' || action.name.trim().length === 0) {
                    throw new Error('The action name is invalid');
                }

                if (!action.hasOwnProperty('type')) {
                    throw new Error('The action must have a value for the type');
                }

                const actionTypes = Object.keys(ActionType).map(actionType => ActionType[actionType]);

                if (!actionTypes.includes(action.type)) {
                    throw new Error('The action type is invalid');
                }

                if (!action.hasOwnProperty('context')) {
                    throw new Error('The action must have a value for the context');
                }

                if (typeof action.context !== 'string' || action.context.trim().length === 0) {
                    throw new Error('The action context is invalid');
                }

            }

        }

        // Initial state validation
        if (initializationData.hasOwnProperty('initialStateId') && !!initializationData.initialStateId) {

            if (!isUUID(initializationData.initialStateId)) {
                throw new Error('Invalid state ID');
            }
        }

        // Custom attributes validation
        if (!Array.isArray(initializationData.customAttributes)) {
            throw new Error('Custom Attributes must be an array');
        }

        for (const customAttribute of initializationData.customAttributes) {

            if (!customAttribute.hasOwnProperty('id')) {
                throw new Error('The custom attribute must have a value for the ID');
            }

            if (!isUUID(customAttribute.id)) {
                throw new Error('The custom attribute ID is invalid');
            }

            if (!customAttribute.hasOwnProperty('name')) {
                throw new Error('The custom attribute must have a value for the name');
            }

            if (typeof customAttribute.name !== 'string' || customAttribute.name.trim().length === 0) {
                throw new Error('The custom attribute name is invalid');
            }

            if (!customAttribute.hasOwnProperty('isRequired')) {
                throw new Error('The custom attribute must have a value for the is required field');
            }

            if (typeof customAttribute.isRequired !== 'boolean') {
                throw new Error('The custom attribute is-required is invalid');
            }

            if (!customAttribute.hasOwnProperty('isInTable')) {
                throw new Error('The custom attribute must have a value for the is in table field');
            }

            if (typeof customAttribute.isInTable !== 'boolean') {
                throw new Error('The custom attribute is-in-table is invalid');
            }

            // Custom attribute options validation
            if (!Array.isArray(customAttribute.options)) {
                throw new Error('Custom Attributes options must be an array');
            }

            for (const customAttributeOption of customAttribute.options) {

                if (!customAttributeOption.hasOwnProperty('id')) {
                    throw new Error('The custom attribute option must have a value for the ID');
                }

                if (!isUUID(customAttributeOption.id)) {
                    throw new Error('The custom attribute option ID is invalid');
                }

                if (!customAttributeOption.hasOwnProperty('name')) {
                    throw new Error('The custom attribute option must have a value for the name');
                }

                if (typeof customAttributeOption.name !== 'string' || customAttributeOption.name.trim().length === 0) {
                    throw new Error('The custom attribute option name is invalid');
                }

            }

        }

        // End validation



        // Instantiation
        const newInstance = new ToDoTemplate();

        if ('id' in initializationData) {
            newInstance.id = initializationData.id;
        } else {
            newInstance.id = uuid.v4();
        }

        if (!!initializationData.initialStateId) {
            newInstance.initialStateId = initializationData.initialStateId;
        }

        if (!!initializationData.department) {
            newInstance.department = initializationData.department;
        }

        newInstance.archived = initializationData.archived;

        newInstance.name = initializationData.name;
        newInstance.binding = initializationData.binding;
        newInstance.site = initializationData.site;
        newInstance.assetTemplate = initializationData.assetTemplate;
        newInstance.states = initializationData.states;
        newInstance.customAttributes = initializationData.customAttributes;
        newInstance.questions = initializationData.questions.map(questionObject => getQuestionFromPlainObject(questionObject));

        return newInstance;
    }

    private getQuestionInList(questions: Array<Question>, questionId: string): Question|undefined {

        let selectedQuestion: Question|undefined = questions.find(question => question.id === questionId);

        if (!!selectedQuestion) {
            return selectedQuestion;
        }

        for (const question of questions) {
            if (question.type === QuestionType.BOOLEAN || question.type === QuestionType.NUMBER || question.type === QuestionType.TEXT) {
                selectedQuestion = this.getQuestionInList(question.nesting.pass.questions, questionId);

                if (!!selectedQuestion) {
                    return selectedQuestion;
                }
                
                selectedQuestion = this.getQuestionInList(question.nesting.fail.questions, questionId);

                if (!!selectedQuestion) {
                    return selectedQuestion;
                }

                selectedQuestion = this.getQuestionInList(question.nesting.flag.questions, questionId);

                if (!!selectedQuestion) {
                    return selectedQuestion;
                }
            } else if (question.type === QuestionType.SINGLE_SELECT || question.type === QuestionType.MULTI_SELECT) {
                for (const choice of question.choices) {
                    selectedQuestion = this.getQuestionInList(choice.questions, questionId);

                    if (!!selectedQuestion) {
                        return selectedQuestion;
                    }
                }
            }
        }

        return undefined;
    }

    getQuestionFromId(questionId: string) {
        return this.getQuestionInList(this.questions, questionId);
    }

    private getParentQuestionInList(questions: Array<Question>, questionId: string): Question|undefined {

        let selectedQuestion: Question|undefined;

        for (const question of questions) {
            if (question.type === QuestionType.BOOLEAN || question.type === QuestionType.NUMBER || question.type === QuestionType.TEXT) {

                if (
                    question.nesting.pass.questions.find(nestedQuestion => nestedQuestion.id === questionId) || 
                    question.nesting.fail.questions.find(nestedQuestion => nestedQuestion.id === questionId) || 
                    question.nesting.flag.questions.find(nestedQuestion => nestedQuestion.id === questionId)
                ) {
                    return question;
                }

                
                selectedQuestion = this.getQuestionInList(question.nesting.pass.questions, questionId);

                if (!!selectedQuestion) {
                    return selectedQuestion;
                }
                
                selectedQuestion = this.getQuestionInList(question.nesting.fail.questions, questionId);

                if (!!selectedQuestion) {
                    return selectedQuestion;
                }

                selectedQuestion = this.getQuestionInList(question.nesting.flag.questions, questionId);

                if (!!selectedQuestion) {
                    return selectedQuestion;
                }
            } else if (question.type === QuestionType.SINGLE_SELECT || question.type === QuestionType.MULTI_SELECT) {
                for (const choice of question.choices) {

                    if (choice.questions.find(nestedQuestion => nestedQuestion.id === questionId)) {
                        return question;
                    }

                    selectedQuestion = this.getQuestionInList(choice.questions, questionId);

                    if (!!selectedQuestion) {
                        return selectedQuestion;
                    }
                }
            }
        }

        return undefined;
    }

    getAncestorQuestionsFromId(questionId: string) {
        const ancestorQuestions = [questionId];
        let lastQuestionId = questionId;

        if ((this.questions.find(question => question.id === lastQuestionId))) {
            return ancestorQuestions;
        }

        while (!!lastQuestionId) {
            const lastQuestion = this.getParentQuestionInList(this.questions, lastQuestionId);
            if (lastQuestion) {
                lastQuestionId = lastQuestion.id;
                ancestorQuestions.push(lastQuestionId);
            } else {
                lastQuestionId = undefined;
            }
        }

        return ancestorQuestions;
    }

    private getActionInList(questions: Array<Question>, actionId: string): ChecklistAction|undefined {

        let selectedAction: TriggerTaskAction;

        for (const question of questions) {
            if (question.type === QuestionType.BOOLEAN || question.type === QuestionType.NUMBER || question.type === QuestionType.TEXT) {

                selectedAction = question.nesting.pass.actions.find(action => action.id === actionId);

                if (!!selectedAction) {
                    return selectedAction;
                }

                selectedAction = question.nesting.fail.actions.find(action => action.id === actionId);

                if (!!selectedAction) {
                    return selectedAction;
                }

                selectedAction = question.nesting.flag.actions.find(action => action.id === actionId);

                if (!!selectedAction) {
                    return selectedAction;
                }

                selectedAction = this.getActionInList(question.nesting.pass.questions, actionId);

                if (!!selectedAction) {
                    return selectedAction;
                }
                
                selectedAction = this.getActionInList(question.nesting.fail.questions, actionId);

                if (!!selectedAction) {
                    return selectedAction;
                }

                selectedAction = this.getActionInList(question.nesting.flag.questions, actionId);

                if (!!selectedAction) {
                    return selectedAction;
                }
            } else if (question.type === QuestionType.SINGLE_SELECT || question.type === QuestionType.MULTI_SELECT) {
                for (const choice of question.choices) {

                    selectedAction = choice.actions.find(action => action.id === actionId);
    
                    if (!!selectedAction) {
                        return selectedAction;
                    }
                    
                    selectedAction = this.getActionInList(choice.questions, actionId);

                    if (!!selectedAction) {
                        return selectedAction;
                    }
                }
            }
        }

        return undefined;
    }

    getActionFromId(actionId: string) {
        return this.getActionInList(this.questions, actionId);
    }

    private getUserActionPromptInList(questions: Array<Question>, actionId: string): UserActionPrompt|undefined {

        let selectedActionPrompt: UserActionPrompt;

        for (const question of questions) {
            if (question.type === QuestionType.BOOLEAN || question.type === QuestionType.NUMBER || question.type === QuestionType.TEXT) {

                selectedActionPrompt = question.nesting.pass.userActionPrompts.find(action => action.id === actionId);

                if (!!selectedActionPrompt) {
                    return selectedActionPrompt;
                }

                selectedActionPrompt = question.nesting.fail.userActionPrompts.find(action => action.id === actionId);

                if (!!selectedActionPrompt) {
                    return selectedActionPrompt;
                }

                selectedActionPrompt = question.nesting.flag.userActionPrompts.find(action => action.id === actionId);

                if (!!selectedActionPrompt) {
                    return selectedActionPrompt;
                }

                selectedActionPrompt = this.getUserActionPromptInList(question.nesting.pass.questions, actionId);

                if (!!selectedActionPrompt) {
                    return selectedActionPrompt;
                }
                
                selectedActionPrompt = this.getUserActionPromptInList(question.nesting.fail.questions, actionId);

                if (!!selectedActionPrompt) {
                    return selectedActionPrompt;
                }

                selectedActionPrompt = this.getUserActionPromptInList(question.nesting.flag.questions, actionId);

                if (!!selectedActionPrompt) {
                    return selectedActionPrompt;
                }
            } else if (question.type === QuestionType.SINGLE_SELECT || question.type === QuestionType.MULTI_SELECT) {
                for (const choice of question.choices) {

                    selectedActionPrompt = choice.userActionPrompts.find(action => action.id === actionId);
    
                    if (!!selectedActionPrompt) {
                        return selectedActionPrompt;
                    }
                    
                    selectedActionPrompt = this.getUserActionPromptInList(choice.questions, actionId);

                    if (!!selectedActionPrompt) {
                        return selectedActionPrompt;
                    }
                }
            }
        }

        return undefined;
    }

    getUserActionPromptFromId(actionId: string) {
        return this.getUserActionPromptInList(this.questions, actionId);
    }

    convertToPlainObject() {
        return {
            ...this as ToDoTemplate,
        };
    }

    update(updateObject: any) {
        const { id, ...otherParams } = updateObject;

        for (const classKey in this) {
            if (otherParams.hasOwnProperty(classKey)) {
                this[classKey] = otherParams[classKey];
            }
        }
    }
}