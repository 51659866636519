import CustomAttribute, { CustomAttributeValues } from './custom-attribute';
import { ToDoStatus } from './to-do';

export enum ActionType {
    STATE_TRANSITION = 'S',
}

export class Action {
    name: string;
    type: ActionType;
    context: string;
}

export default class State {
    id: string;
    name: string;
    status?: ToDoStatus;
    canDispatcherExecute: boolean;
    canTechnicianExecute: boolean;
    actions: Array<Action>;
    customAttributes: Array<CustomAttribute>;
}

export interface StateSnapshot {
    stateId: string;
    transitionTime: string;
    customAttributeValues?: CustomAttributeValues;
}
