import ToDo, { ToDoStatus } from '../models/to-do';
import { TO_DO_TEMPLATES } from './to-do-template';
import { ASSETS } from './asset';
import { STATES } from './state';
import { EMPLOYEES } from './employee';

export const TO_DOS: Array<ToDo> = [ToDo.getInstanceFromObject({
    id: '68fe732c-cd8e-4ec4-97d0-acb5d5378435',
    readableId: '12832',
    toDoTemplate: TO_DO_TEMPLATES[0].id,
    status: ToDoStatus.CREATED,
    createdTime: '2020-01-20T11:26:03',
    startTime: '2020-02-25T00:00:00',
    dueTime: '2020-02-28T00:00:00',
    asset: ASSETS[0].id,
    assignedUser: EMPLOYEES[0].userId,

    customAttributeValues: {
        [TO_DO_TEMPLATES[0].customAttributes[0].id]: TO_DO_TEMPLATES[0].customAttributes[0].options[0].id,
    },
    currentStateId: STATES[0].id,
    history: [{
        stateId: STATES[0].id,
        transitionTime: '2020-01-17T16:25:11',
    }],
})];
