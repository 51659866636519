import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage, Storable } from './storable';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { UrlService } from '../services/url.service';

export class StorageWrapper<T extends Storable> {
    entries: Array<T> = [];
    activeEntries: Array<T> = [];

    pushTimer: number|undefined = 0;

    createdIds: Set<string> = new Set();
    updatedIds: Set<string> = new Set();
    deletedIds: Set<string> = new Set();

    allEntriesSubject: BehaviorSubject<Array<T>>;
    individualEntrySubjects: {
        [entityId: string]: BehaviorSubject<T|undefined>;
    };

    constructor(
        private storageKey: string,
        private apiEndpoint: string,
        private initializer: (initializationData: any) => T,
        private storage: Storage,
        private authenticationService: AuthenticationService,
        private urlService: UrlService,
        private http: HttpClient,
        private seedData?: T[],
    ) {
        this.allEntriesSubject = new BehaviorSubject(this.activeEntries);
        this.individualEntrySubjects = {};
        this.initializeAllEntriesSubject();
    }

    private async initializeAllEntriesSubject() {
        await this.getEntries();
        this.allEntriesSubject.next(this.activeEntries);
    }

    private async deserializeEntries() {
        const parsedEntries = await this.storage.get(this.storageKey);
        const instantiatedEntries: Array<T> = parsedEntries.map((entry: any) => this.initializer(entry));
        return instantiatedEntries;
    }

    private async serializeEntries() {
        const plainObjectEntries = this.entries.map(entry => entry.convertToPlainObject());
        return this.storage.set(this.storageKey, plainObjectEntries);
    }

    private async getDataFromServer() {
        
        const headers = new HttpHeaders({
            Authorization: `Token ${this.authenticationService.getToken()}`,
        });
        const httpOptions = {
            headers,
        };

        return this.http.get(this.urlService.getBaseURL() + 'api/' + this.apiEndpoint + '/', httpOptions).toPromise();
    }

    async pushDataToServer() {

        if (!window.navigator.onLine) {
            return;
        }

        const headers = new HttpHeaders({
            Authorization: `Token ${this.authenticationService.getToken()}`,
        });
        const httpOptions = {
            headers,
        };

        const entries = this.entries.map(entry => this.initializer(entry.convertToPlainObject()));
        const createdIds = new Set(this.createdIds);
        const updatedIds = new Set(this.updatedIds);
        const deletedIds = new Set(this.deletedIds);

        this.createdIds = new Set();
        this.updatedIds = new Set();
        this.deletedIds = new Set();

        for (const createdId of createdIds) {
            const entry = entries.find(entryInStorable => entryInStorable.id === createdId);
            await this.http.post(
                this.urlService.getBaseURL() + 'api/' + this.apiEndpoint + '/',
                entry,
                httpOptions
            ).toPromise();
        }

        for (const updatedId of updatedIds) {
            const entry = entries.find(entryInStorable => entryInStorable.id === updatedId);
            await this.http.put(
                this.urlService.getBaseURL() + 'api/' + this.apiEndpoint + '/' + updatedId + '/',
                entry,
                httpOptions
            ).toPromise();
        }

        for (const deletedId of deletedIds) {
            await this.http.delete(
                this.urlService.getBaseURL() + 'api/' + this.apiEndpoint + '/' + deletedId + '/',
                httpOptions
            ).toPromise();
        }
    }

    requestServerPush() {
        return new Promise((resolve, reject) => {
            if (this.pushTimer) {
                window.clearTimeout(this.pushTimer);
                this.pushTimer = undefined;
            }
    
            this.pushTimer = window.setTimeout(async () => {
                await this.pushDataToServer();
                resolve();
            }, 100);
        });
    }

    private async pullEntriesFromStorage() {
        const entriesInStorage = await this.storage.get(this.storageKey);

        if (!entriesInStorage) {
            this.entries = this.seedData;
            await this.serializeEntries();
        } else {
            this.entries = await this.deserializeEntries();
        }

        this.activeEntries = this.entries.filter(entry => !entry.archived);
        this.allEntriesSubject.next(this.activeEntries);
    }

    async getEntries(force = false) {
        if (this.entries.length === 0 || force) {
            let entriesInStorage: any;

            if (window.navigator.onLine && !!this.authenticationService.getToken()) {
                await this.pullEntriesFromStorage();
                const receivedData: any = await this.getDataFromServer();
                this.entries = receivedData.map((entry: object) => {
                    return this.initializer(entry);
                });
                await this.serializeEntries();
            } else {
                entriesInStorage = await this.storage.get(this.storageKey);

                if (!entriesInStorage) {
                    this.entries = this.seedData;
                    await this.serializeEntries();
                } else {
                    this.entries = await this.deserializeEntries();
                }
            }

            this.activeEntries = this.entries.filter(entry => !entry.archived);
        }

        return this.activeEntries;
    }

    async refreshEntries() {
        const entries = await this.getEntries(true);

        setTimeout(() => {
            this.allEntriesSubject.next(entries);
    
            for (const entityId in this.individualEntrySubjects) {
                const entity = entries.find(entry => entry.id === entityId);
                this.individualEntrySubjects[entityId].next(entity);
            }
        });
    }

    getEntriesObservable() {
        return this.allEntriesSubject.asObservable();
    }

    getEntryObservable(id: string) {
        if (!(id in this.individualEntrySubjects)) {
            const newIndividualSubject: BehaviorSubject<T> = new BehaviorSubject(undefined);
            this.individualEntrySubjects[id] = newIndividualSubject;

            this.getEntryById(id).then(entity => newIndividualSubject.next(entity));
        }

        return this.individualEntrySubjects[id].asObservable();
    }

    async addEntry(newEntry: T) {
        // Make sure the old entries have been fetched before adding new ones
        await this.getEntries();

        this.entries.push(newEntry);
        this.activeEntries.push(newEntry);

        this.createdIds.add(newEntry.id);
        this.serializeEntries();

        this.allEntriesSubject.next(this.activeEntries);

        if (!!this.authenticationService.getToken()) {
            return this.requestServerPush();
        }

    }

    async getEntryById(id: string) {
        const journalEntries = await this.getEntries();
        return journalEntries.find(entry => entry.id === id);
    }

    async updateEntry(updateObject: Storable) {
        // Make sure the old entries have been fetched before updating them
        await this.getEntries();

        const entryToUpdate = this.entries.find(entry => entry.id === updateObject.id);
        entryToUpdate.update(updateObject);
        const newActiveEntries = this.entries.filter(entry => !entry.archived);
        this.activeEntries.splice(0, Infinity, ...newActiveEntries);

        this.updatedIds.add(updateObject.id);
        this.serializeEntries();

        this.allEntriesSubject.next(this.activeEntries);
        if (updateObject.id in this.individualEntrySubjects) {
            this.individualEntrySubjects[updateObject.id].next(entryToUpdate);
        }

        if (!!this.authenticationService.getToken()) {
            return this.requestServerPush();
        }
    }

    async softDeleteEntry(deletedId: string) {
        // Make sure the old entries have been fetched before soft-deleting them
        await this.getEntries();

        const deletedEntry = this.entries.find(entry => entry.id === deletedId);
        deletedEntry.archived = true;

        const newActiveEntries = this.entries.filter(entry => !entry.archived);
        this.activeEntries.splice(0, Infinity, ...newActiveEntries);

        this.updatedIds.add(deletedId);
        this.serializeEntries();

        this.allEntriesSubject.next(this.activeEntries);
        if (deletedId in this.individualEntrySubjects) {
            this.individualEntrySubjects[deletedId].next(deletedEntry);
        }

        if (!!this.authenticationService.getToken()) {
            return this.requestServerPush();
        }
    }

    async hardDeleteEntry(deletedId: string) {
        // Make sure the old entries have been fetched before hard-deleting them
        await this.getEntries();

        const deletedEntryIndex = this.entries.findIndex(entry => entry.id === deletedId);
        this.entries.splice(deletedEntryIndex, 1);
        const deletedActiveEntryIndex = this.activeEntries.findIndex(entry => entry.id === deletedId);
        this.activeEntries.splice(deletedActiveEntryIndex, Infinity);

        this.deletedIds.add(deletedId);
        this.serializeEntries();

        this.allEntriesSubject.next(this.activeEntries);
        if (deletedId in this.individualEntrySubjects) {
            this.individualEntrySubjects[deletedId].next(undefined);
        }

        if (!!this.authenticationService.getToken()) {
            return this.requestServerPush();
        }
    }
}
