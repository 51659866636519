import { isUUID } from '../utilities';
import * as uuid from 'uuid';

export enum QuestionType {
    BOOLEAN = 'B',
    NUMBER = 'N',
    TEXT = 'T',
    IMAGE = 'I',

    SINGLE_SELECT = 'SS',
    MULTI_SELECT = 'MS',
};

export enum Unit {
    NONE = 'N',
    VOLTAGE = 'V',
    CELCIUS = 'C',
};

export enum ChecklistActionType {
    TRIGGER_TASK = 'T',
}

interface BaseChecklistAction {
    id: string;
    type: ChecklistActionType;
    archived?: boolean;
}

export interface TriggerTaskAction extends BaseChecklistAction {
    type: ChecklistActionType.TRIGGER_TASK;
    description: string;
    toDoTemplate: string;
    assignedUser: string;
    startTimeDeltaInHours?: number;
    dueTimeDeltaInHours: number;
    asset?: string;
    location?: string;
}

export type ChecklistAction = TriggerTaskAction;

export enum UserActionPromptType {
    MESSAGE = 'M',
}

interface BaseUserActionPrompt {
    id: string;
    type: UserActionPromptType;
    archived?: boolean;

    questions: Array<Question>;
    actions: Array<ChecklistAction>;
    userActionPrompts: Array<UserActionPrompt>;
}

export interface ShowMessageAction extends BaseUserActionPrompt {
    type: UserActionPromptType.MESSAGE;
    message: string;
}

export type UserActionPrompt = ShowMessageAction;

export interface Nesting {
    pass: {
        questions: Array<Question>,
        actions: Array<ChecklistAction>,
        userActionPrompts: Array<UserActionPrompt>,
    },
    fail: {
        questions: Array<Question>,
        actions: Array<ChecklistAction>,
        userActionPrompts: Array<UserActionPrompt>,
    };
    flag: {
        questions: Array<Question>,
        actions: Array<ChecklistAction>,
        userActionPrompts: Array<UserActionPrompt>,
    };
}

export enum Operator {
    LESSER_THAN = 'LT',
    GREATER_THAN = 'GT',
    EQUAL_TO = 'E',
    NOT_EQUAL_TO = 'NE',
    STARTS_WITH = 'SW',
    ENDS_WITH = 'EW',
    INCLUDES = 'I',
}

export enum ResultType {
    PASS = 'P',
    FAIL = 'F',
    FLAG = 'FL',
}

export interface Condition {
    operator: Operator;

    leftOperand: string;
    rightOperand: string;
}

export interface Rule {
    id: string;
    condition: Condition;
    result: ResultType;
    archived?: boolean;
}

interface BaseQuestion {
    id: string;
    title?: string;
    type: QuestionType;
    isRequired: boolean;
    archived?: boolean;
}

export interface BooleanQuestion extends BaseQuestion {
    type: QuestionType.BOOLEAN,

    successLabel?: string;
    failureLabel?: string;

    nesting: Nesting;
}

export interface NumberQuestion extends BaseQuestion {
    type: QuestionType.NUMBER;
    placeholder?: string;
    successLabel?: string;
    unit?: Unit,
    rules: Array<Rule>;
    nesting: Nesting;
}

export interface TextQuestion extends BaseQuestion {
    type: QuestionType.TEXT;
    placeholder?: string;
    rules: Array<Rule>;
    nesting: Nesting;
}

export interface ImageQuestion extends BaseQuestion {
    type: QuestionType.IMAGE;
}

export interface Choice {
    id: string;
    label: string;
    value: string;
    type?: ResultType;
    archived?: boolean;

    questions: Array<Question>;
    actions: Array<ChecklistAction>;
    userActionPrompts: Array<UserActionPrompt>;
}

export interface SingleSelectQuestion extends BaseQuestion {
    type: QuestionType.SINGLE_SELECT;
    choices: Array<Choice>;
}

export interface MultiSelectQuestion extends BaseQuestion {
    type: QuestionType.MULTI_SELECT;
    choices: Array<Choice>;
}

function validateConditionObject(conditionObject: any) {
    const allOperatorTypes: Array<Operator> = [];

    for (const operatorType in Operator) {
        allOperatorTypes.push(Operator[operatorType] as Operator);
    }

    if (!conditionObject.hasOwnProperty('operator')) {
        throw new Error('The condition must have an operator');
    }

    if (!allOperatorTypes.includes(conditionObject.operator)) {
        throw new Error(`Invalid operator. The valid operators are: ${allOperatorTypes.join(', ')}`);
    }

    if (!conditionObject.hasOwnProperty('leftOperand')) {
        throw new Error('The condition must have a left operand');
    }

    if (typeof conditionObject.leftOperand !== 'string') {
        throw new Error('The left operand must be a string');
    }

    if (!conditionObject.hasOwnProperty('rightOperand')) {
        throw new Error('The condition must have a right operand');
    }

    if (typeof conditionObject.rightOperand !== 'string') {
        throw new Error('The right operand must be a string');
    }
}

function validateRuleObject(rulesObject: any) {
    const allResultTypes: Array<ResultType> = [];

    for (const resultType in ResultType) {
        allResultTypes.push(ResultType[resultType] as ResultType);
    }

    if (rulesObject.hasOwnProperty('id')) {
        if (!isUUID(rulesObject.id)) {
            throw new Error('Invalid ID for rule');
        }
    }

    if (!!rulesObject.result && typeof rulesObject.result !== 'string') {
        throw new Error('The rule result must be a string');
    }

    if (!allResultTypes.includes(rulesObject.result)) {
        throw new Error(`Invalid rule result. The valid types are: ${allResultTypes.join(', ')}`);
    }

    if (rulesObject.hasOwnProperty('condition')) {
        validateConditionObject(rulesObject.condition);
    }

}

function validateNestingSegment(nestingSegment: any) {
    if (!nestingSegment.hasOwnProperty('questions')) {
        nestingSegment.questions = [];
    }
    
    if (!nestingSegment.hasOwnProperty('actions')) {
        nestingSegment.actions = [];
    }
    
    if (!nestingSegment.hasOwnProperty('userActionPrompts')) {
        nestingSegment.userActionPrompts = [];
    }

    for (const question of nestingSegment.questions) {
        validateQuestionObject(getQuestionFromPlainObject(question));
    }
}

function validateNestingObject(nestingObject: any) {
    if (nestingObject.hasOwnProperty('pass')) {
        validateNestingSegment(nestingObject.pass);
    }
    
    if (nestingObject.hasOwnProperty('fail')) {
        validateNestingSegment(nestingObject.fail);
    }
    
    if (nestingObject.hasOwnProperty('flag')) {
        validateNestingSegment(nestingObject.flag);
    }
}

function validateChoiceObject(choiceObject: any) {
    const allResultTypes: Array<ResultType> = [];

    for (const resultType in ResultType) {
        allResultTypes.push(ResultType[resultType] as ResultType);
    }

    if (choiceObject.hasOwnProperty('id')) {
        if (!isUUID(choiceObject.id)) {
            throw new Error('Invalid ID for choice');
        }
    }

    if (!choiceObject.hasOwnProperty('label')) {
        throw new Error('The choice must have a label');
    }

    if (typeof choiceObject.label !== 'string') {
        throw new Error('The choice label must be a string');
    }

    if (!choiceObject.hasOwnProperty('value')) {
        throw new Error('The choice must have a value');
    }

    if (typeof choiceObject.value !== 'string') {
        throw new Error('The choice value must be a string');
    }

    if (choiceObject.hasOwnProperty('type') && !!choiceObject.type) {

        if (typeof choiceObject.type !== 'string') {
            throw new Error('The choice type must be a string');
        }

        if (!allResultTypes.includes(choiceObject.type)) {
            throw new Error(`Invalid choice type. The valid types are: ${allResultTypes.join(', ')}`);
        }
    }
}

export function validateQuestionObject(questionObject: any) {
    const allQuestionTypes: Array<QuestionType> = [];
    const allUnitTypes: Array<Unit> = [];

    for (const questionType in QuestionType) {
        allQuestionTypes.push(QuestionType[questionType] as QuestionType)
    }

    for (const unit in Unit) {
        allUnitTypes.push(Unit[unit] as Unit)
    }

    if (typeof questionObject !== 'object') {
        throw new Error('The question data must be an object');
    }

    if (!questionObject.hasOwnProperty('type')) {
        throw new Error('The question must have a value for the type');
    }

    if (!allQuestionTypes.includes(questionObject.type)) {
        throw new Error(`Invalid question type. Valid types are: ${allQuestionTypes.join(', ')}`);
    }

    if (!questionObject.hasOwnProperty('isRequired')) {
        throw new Error('The question must have a value for the is required property');
    }

    if (typeof questionObject.isRequired !== 'boolean') {
        throw new Error('The is required property must be a boolean');
    }

    if (questionObject.hasOwnProperty('id')) {
        if (!isUUID(questionObject.id)) {
            throw new Error('Invalid ID for question');
        }
    }

    if (questionObject.hasOwnProperty('title') && !!questionObject.title) {
        if (typeof questionObject.title !== 'string') {
            throw new Error('The question title must be a string');
        }
    }

    switch(questionObject.type) {
        case QuestionType.BOOLEAN:
            if (questionObject.hasOwnProperty('successLabel') && !!questionObject.successLabel) {
                if (typeof questionObject.successLabel !== 'string') {
                    throw new Error('The success label must be a string');
                }
            }

            if (questionObject.hasOwnProperty('failureLabel') && !!questionObject.failureLabel) {
                if (typeof questionObject.failureLabel !== 'string') {
                    throw new Error('The failure label must be a string');
                }
            }

            if (questionObject.hasOwnProperty('nesting')) {
                validateNestingObject(questionObject.nesting);
            }
            break;

        case QuestionType.IMAGE:
            break;

        case QuestionType.NUMBER:
            if (questionObject.hasOwnProperty('successLabel') && !!questionObject.successLabel) {
                if (typeof questionObject.successLabel !== 'string') {
                    throw new Error('The success label must be a string');
                }
            }

            if (questionObject.hasOwnProperty('placeholder') && !!questionObject.placeholder) {
                if (typeof questionObject.placeholder !== 'string') {
                    throw new Error('The placeholder must be a string');
                }
            }

            if (questionObject.hasOwnProperty('unit') && !!questionObject.unit) {
                if (typeof questionObject.unit !== 'string') {
                    throw new Error('The unit must be a string');
                }

                if (!allUnitTypes.includes(questionObject.unit)) {
                    throw new Error(`Invalid unit. Valid unit types are: ${allUnitTypes.join(', ')}`);
                }
            }

            if (questionObject.hasOwnProperty('rules')) {
                if (!!questionObject.rules && !Array.isArray(questionObject.rules)) {
                    throw new Error('Rules must be an array');
                }

                for (const rule of questionObject.rules) {
                    validateRuleObject(rule);
                }

            }

            if (questionObject.hasOwnProperty('nesting')) {
                validateNestingObject(questionObject.nesting);
            }

            break;

        case QuestionType.TEXT:
            if (questionObject.hasOwnProperty('placeholder') && !!questionObject.placeholder) {
                if (typeof questionObject.placeholder !== 'string') {
                    throw new Error('The placeholder must be a string');
                }
            }

            if (questionObject.hasOwnProperty('rules')) {
                if (!!questionObject.rules && !Array.isArray(questionObject.rules)) {
                    throw new Error('Rules must be an array');
                }

                for (const rule of questionObject.rules) {
                    validateRuleObject(rule);
                }

            }

            if (questionObject.hasOwnProperty('nesting')) {
                validateNestingObject(questionObject.nesting);
            }

            break;
        
        case QuestionType.SINGLE_SELECT:
        case QuestionType.MULTI_SELECT:

            if (!questionObject.hasOwnProperty('choices')) {
                throw new Error('The question must have choices');
            }

            if (!Array.isArray(questionObject.choices)) {
                throw new Error('Choices must be an array');
            }

            for (const choice of questionObject.choices) {
                validateChoiceObject(choice);
            }

            break;
        default:
            break;
    }
}

function getRuleFromPlainObject(plainObject: any): Rule {
    const ruleId = plainObject.id ? plainObject.id : uuid.v4();

    const defaultValues = {
        archived: false,
    };

    for (const defaultKey in defaultValues) {
        if (!plainObject.hasOwnProperty(defaultKey)) {
            plainObject[defaultKey] = defaultValues[defaultKey];
        }
    }

    return {
        id: ruleId,
        condition: plainObject.condition,
        result: plainObject.result,
        archived: plainObject.archived,
    }
}

function getChoiceFromPlainObject(plainObject: any): Choice {
    const choiceId = plainObject.id ? plainObject.id : uuid.v4();

    const defaultValues = {
        archived: false,
    };

    for (const defaultKey in defaultValues) {
        if (!plainObject.hasOwnProperty(defaultKey)) {
            plainObject[defaultKey] = defaultValues[defaultKey];
        }
    }

    return {
        id: choiceId,
        label: plainObject.label,
        value: plainObject.value,
        archived: plainObject.archived,
        type: plainObject.type,
        questions: plainObject.questions ? plainObject.questions.map(questionObject => getQuestionFromPlainObject(questionObject)) : [],
        actions: plainObject.actions,
        userActionPrompts: plainObject.userActionPrompts,
    }
}

export function getQuestionFromPlainObject(plainObject: any): Question {
    const defaultValues = {
        isRequired: false,
        archived: false,
    };

    for (const defaultKey in defaultValues) {
        if (!plainObject.hasOwnProperty(defaultKey)) {
            plainObject[defaultKey] = defaultValues[defaultKey];
        }
    }

    validateQuestionObject(plainObject);

    const questionId = plainObject.hasOwnProperty('id') ? plainObject.id : uuid.v4();
    const questionTitle = plainObject.hasOwnProperty('title') ? plainObject.title : undefined;
    const questionType = plainObject.type;
    const questionIsRequired = plainObject.isRequired;
    const questionIsArchived = plainObject.archived;

    switch(questionType) {
        case QuestionType.BOOLEAN:
            const booleanSuccessLabel = plainObject.successLabel;
            const booleanFailureLabel = plainObject.failureLabel;

            const booleanNesting: Nesting = {
                pass: {
                    questions: plainObject.nesting.pass && plainObject.nesting.pass.questions ? plainObject.nesting.pass.questions.map(questionObject => getQuestionFromPlainObject(questionObject)) : [],
                    actions: plainObject.nesting.pass && plainObject.nesting.pass.actions ? plainObject.nesting.pass.actions : [],
                    userActionPrompts: plainObject.nesting.pass && plainObject.nesting.pass.userActionPrompts ? plainObject.nesting.pass.userActionPrompts : [],
                },
                fail: {
                    questions: plainObject.nesting.fail && plainObject.nesting.fail.questions ? plainObject.nesting.fail.questions.map(questionObject => getQuestionFromPlainObject(questionObject)) : [],
                    actions: plainObject.nesting.fail && plainObject.nesting.fail.actions ? plainObject.nesting.fail.actions : [],
                    userActionPrompts: plainObject.nesting.fail && plainObject.nesting.fail.userActionPrompts ? plainObject.nesting.fail.userActionPrompts : [],
                },
                flag: {
                    questions: plainObject.nesting.flag && plainObject.nesting.flag.questions ? plainObject.nesting.flag.questions.map(questionObject => getQuestionFromPlainObject(questionObject)) : [],
                    actions: plainObject.nesting.flag && plainObject.nesting.flag.actions ? plainObject.nesting.flag.actions : [],
                    userActionPrompts: plainObject.nesting.flag && plainObject.nesting.flag.userActionPrompts ? plainObject.nesting.flag.userActionPrompts : [],
                },
            };

            return {
                id: questionId,
                type: questionType,
                archived: questionIsArchived,
                isRequired: questionIsRequired,
                title: questionTitle,
                successLabel: booleanSuccessLabel,
                failureLabel: booleanFailureLabel,
                nesting: booleanNesting,
            };

        case QuestionType.IMAGE:

            return {
                id: questionId,
                type: questionType,
                archived: questionIsArchived,
                isRequired: questionIsRequired,
                title: questionTitle,
            };

        case QuestionType.NUMBER:
            const numberSuccessLabel = plainObject.successLabel;
            const numberPlaceholder = plainObject.placeholder;
            const numberUnit = plainObject.unit;
            const numberRules: Array<Rule> = plainObject.rules ? plainObject.rules.map(ruleObject => getRuleFromPlainObject(ruleObject)) : [];
            const numberNesting: Nesting = {
                pass: {
                    questions: plainObject.nesting.pass && plainObject.nesting.pass.questions ? plainObject.nesting.pass.questions.map(questionObject => getQuestionFromPlainObject(questionObject)) : [],
                    actions: plainObject.nesting.pass && plainObject.nesting.pass.actions ? plainObject.nesting.pass.actions : [],
                    userActionPrompts: plainObject.nesting.pass && plainObject.nesting.pass.userActionPrompts ? plainObject.nesting.pass.userActionPrompts : [],
                },
                fail: {
                    questions: plainObject.nesting.fail && plainObject.nesting.fail.questions ? plainObject.nesting.fail.questions.map(questionObject => getQuestionFromPlainObject(questionObject)) : [],
                    actions: plainObject.nesting.fail && plainObject.nesting.fail.actions ? plainObject.nesting.fail.actions : [],
                    userActionPrompts: plainObject.nesting.fail && plainObject.nesting.fail.userActionPrompts ? plainObject.nesting.fail.userActionPrompts : [],
                },
                flag: {
                    questions: plainObject.nesting.flag && plainObject.nesting.flag.questions ? plainObject.nesting.flag.questions.map(questionObject => getQuestionFromPlainObject(questionObject)) : [],
                    actions: plainObject.nesting.flag && plainObject.nesting.flag.actions ? plainObject.nesting.flag.actions : [],
                    userActionPrompts: plainObject.nesting.flag && plainObject.nesting.flag.userActionPrompts ? plainObject.nesting.flag.userActionPrompts : [],
                },
            };

            return {
                id: questionId,
                type: questionType,
                archived: questionIsArchived,
                isRequired: questionIsRequired,
                title: questionTitle,

                successLabel: numberSuccessLabel,
                placeholder: numberPlaceholder,
                unit: numberUnit,
                rules: numberRules,
                nesting: numberNesting
            };

        case QuestionType.TEXT:
            const textPlaceholder = plainObject.placeholder;
            const textRules: Array<Rule> = plainObject.rules ? plainObject.rules.map(ruleObject => getRuleFromPlainObject(ruleObject)) : [];
            const textNesting: Nesting = {
                pass: {
                    questions: plainObject.nesting.pass && plainObject.nesting.pass.questions ? plainObject.nesting.pass.questions.map(questionObject => getQuestionFromPlainObject(questionObject)) : [],
                    actions: plainObject.nesting.pass && plainObject.nesting.pass.actions ? plainObject.nesting.pass.actions : [],
                    userActionPrompts: plainObject.nesting.pass && plainObject.nesting.pass.userActionPrompts ? plainObject.nesting.pass.userActionPrompts : [],
                },
                fail: {
                    questions: plainObject.nesting.fail && plainObject.nesting.fail.questions ? plainObject.nesting.fail.questions.map(questionObject => getQuestionFromPlainObject(questionObject)) : [],
                    actions: plainObject.nesting.fail && plainObject.nesting.fail.actions ? plainObject.nesting.fail.actions : [],
                    userActionPrompts: plainObject.nesting.fail && plainObject.nesting.fail.userActionPrompts ? plainObject.nesting.fail.userActionPrompts : [],
                },
                flag: {
                    questions: plainObject.nesting.flag && plainObject.nesting.flag.questions ? plainObject.nesting.flag.questions.map(questionObject => getQuestionFromPlainObject(questionObject)) : [],
                    actions: plainObject.nesting.flag && plainObject.nesting.flag.actions ? plainObject.nesting.flag.actions : [],
                    userActionPrompts: plainObject.nesting.flag && plainObject.nesting.flag.userActionPrompts ? plainObject.nesting.flag.userActionPrompts : [],
                },
            };

            return {
                id: questionId,
                type: questionType,
                archived: questionIsArchived,
                isRequired: questionIsRequired,
                title: questionTitle,

                placeholder: textPlaceholder,
                rules: textRules,
                nesting: textNesting
            };

        case QuestionType.SINGLE_SELECT:
        case QuestionType.MULTI_SELECT:

            return {
                id: questionId,
                type: questionType,
                archived: questionIsArchived,
                isRequired: questionIsRequired,
                title: questionTitle,
                choices: plainObject.choices ? plainObject.choices.map(choiceObject => getChoiceFromPlainObject(choiceObject)) : [],
            };

        default:
            throw new Error('Invalid question type');
    }
}

export type Question = BooleanQuestion | NumberQuestion | TextQuestion | ImageQuestion | SingleSelectQuestion | MultiSelectQuestion;

export type AnswerType = string|Array<string>|undefined|number|boolean;

export interface Answer {
    questionId: string;
    answerTime: string;
    value: AnswerType;
}

export interface ActionSnapshot {
    actionId: string;
    actionTime: string;
    context: string;
}

export interface UserActionReport {
    userActionId: string;
    actionTime: string;
    context: string;
}

export interface Checklist {
    id: string;
    name: string;
    archived?: boolean;

    questions: Array<Question>;
}