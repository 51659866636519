import { isUUID } from '../utilities';
import moment, { Moment } from 'moment';

export class CustomAttributeOption {
    id: string;
    name: string;
}

export enum CustomAttributeType {
    SINGLE_SELECT = 'S',
    MULTI_SELECT = 'M',
    NUMBER = 'N',
    BOOLEAN = 'B',
    TEXT = 'T',
    DATE = 'D',
    DATE_TIME = 'DT',
    FILE = 'F',
};

export default class CustomAttribute {
    id: string;
    name: string;
    type: CustomAttributeType;
    isRequired: boolean;
    isInTable: boolean;
    options: Array<CustomAttributeOption>;
    defaultValue?: CustomAttributeValueType;
}

export type CustomAttributeValueType = string|Array<string>|undefined|number;

export interface CustomAttributeValues {
    [customFieldId: string]: CustomAttributeValueType;
}

export function validateCustomAttributeObject(customAttributeInputObject: any) {

    if (!customAttributeInputObject.hasOwnProperty('id')) {
        throw new Error('The custom attribute must have a value for the ID');
    }

    if (!isUUID(customAttributeInputObject.id)) {
        throw new Error('The custom attribute ID is invalid');
    }

    if (!customAttributeInputObject.hasOwnProperty('name')) {
        throw new Error('The custom attribute must have a value for the name');
    }

    if (typeof customAttributeInputObject.name !== 'string' || customAttributeInputObject.name.trim().length === 0) {
        throw new Error('The custom attribute name is invalid');
    }

    if (!customAttributeInputObject.hasOwnProperty('isRequired')) {
        throw new Error('The custom attribute must have a value for the is required field');
    }

    if (typeof customAttributeInputObject.isRequired !== 'boolean') {
        throw new Error('The custom attribute is-required is invalid');
    }

    if (!customAttributeInputObject.hasOwnProperty('isInTable')) {
        throw new Error('The custom attribute must have a value for the is in table field');
    }

    if (typeof customAttributeInputObject.isInTable !== 'boolean') {
        throw new Error('The custom attribute is-in-table is invalid');
    }

    // Custom attribute options validation
    if (!Array.isArray(customAttributeInputObject.options)) {
        throw new Error('Custom Attributes options must be an array');
    }

    for (const customAttributeOption of customAttributeInputObject.options) {

        if (!customAttributeOption.hasOwnProperty('id')) {
            throw new Error('The custom attribute option must have a value for the ID');
        }

        if (!isUUID(customAttributeOption.id)) {
            throw new Error('The custom attribute option ID is invalid');
        }

        if (!customAttributeOption.hasOwnProperty('name')) {
            throw new Error('The custom attribute option must have a value for the name');
        }

        if (typeof customAttributeOption.name !== 'string' || customAttributeOption.name.trim().length === 0) {
            throw new Error('The custom attribute option name is invalid');
        }

    }
}

export function getReadableValueForCustomAttribute(value: CustomAttributeValueType, attribute: CustomAttribute) {
    let readableValue = '-';

    if (typeof value === 'undefined') {
        return readableValue;
    }

    switch (attribute.type) {
        case CustomAttributeType.SINGLE_SELECT:
            if (!isUUID(value)) {
                throw new Error('A single select field must have an ID value');
            }

            const selectedOption = attribute.options.find(option => option.id === value);

            if (typeof selectedOption !== 'undefined') {
                readableValue = selectedOption.name;
            }
            break;

        case CustomAttributeType.MULTI_SELECT:
            if (!Array.isArray(value) || value.some(element => !isUUID(element))) {
                throw new Error('A multi select field must have an array of IDs for value');
            }

            const selectedOptions = attribute.options.filter(option => value.includes(option.id));

            if (typeof selectedOption !== 'undefined') {
                readableValue = selectedOptions.join(', ');
            }
            break;

        case CustomAttributeType.NUMBER:
            if (Array.isArray(value)) {
                throw new Error('A number must not be an array type');
            }

            readableValue = String(value);
            break;

        case CustomAttributeType.SINGLE_SELECT:
            if (Array.isArray(value) || typeof value === 'number') {
                throw new Error('The date must be a string type');
            }
            const dateValue = moment(value, 'YYYY-MM-DD');

            if (dateValue.isValid()) {
                readableValue = dateValue.format('DD MMM YYYY');
            }
            break;

        case CustomAttributeType.DATE_TIME:
            if (Array.isArray(value) || typeof value === 'number') {
                throw new Error('The date must be a string type');
            }
            const dateTimeValue = moment(value, 'YYYY-MM-DDTHH:mm:ss');

            if (dateValue.isValid()) {
                readableValue = dateTimeValue.format('DD MMM YYYY hh:mm:ss A');
            }
            break;

        default:
            readableValue = String(value);
    }

    return readableValue;
}

export function getCustomFieldValueFromReadableValue(readableValue: string, attribute: CustomAttribute): CustomAttributeValueType {
    if (!readableValue) {
        return undefined;
    }

    switch (attribute.type) {
        case CustomAttributeType.SINGLE_SELECT:

            const selectedOption = attribute.options.find(option => option.name === readableValue);

            return selectedOption.id;

        case CustomAttributeType.MULTI_SELECT:
            const optionNames = readableValue.split(',').map(optionName => optionName.trim());
            const selectedOptions = attribute.options.filter(option => optionNames.includes(option.name));

            return selectedOptions.map(option => option.id);

        case CustomAttributeType.NUMBER:
            if (isNaN(Number(readableValue))) {
                throw new Error('The input must be a number')
            } else {
                return Number(readableValue);
            }

        case CustomAttributeType.DATE:
            let dateValue: Moment;

            if (moment(readableValue, 'YYYY-MM-DD').isValid()) {
                return readableValue;
            } else if (moment(readableValue, 'DD MMM YYYY').isValid()) {
                dateValue = moment(readableValue, 'DD MMM YYYY');
                return dateValue.format('YYYY-MM-DD');
            } else {
                throw new Error('Unknown date format');
            }

        case CustomAttributeType.DATE_TIME:
            if (moment(readableValue, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
                return readableValue;
            } else if (moment(readableValue, 'DD MMM YYYY hh:mm:ss A').isValid()) {
                dateValue = moment(readableValue, 'DD MMM YYYY hh:mm:ss A');
                return dateValue.format('YYYY-MM-DDTHH:mm:ss');
            } else {
                throw new Error('Unknown date-time format');
            }

        default:
            return readableValue;
    }
}
