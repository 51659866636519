import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from 'src/shared/services/auth-guard.service';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full'
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
		canActivate: [AuthGuard],
	},
    {
		path: 'attendance',
		loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendancePageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'routine',
		loadChildren: () => import('./routine/routine.module').then(m => m.RoutinePageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
	},  {
    path: 'select-asset',
    loadChildren: () => import('./select-asset/select-asset.module').then( m => m.SelectAssetPageModule)
  },
  {
    path: 'select-location',
    loadChildren: () => import('./select-location/select-location.module').then( m => m.SelectLocationPageModule)
  },



];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
