import Location from '../models/location';
import { SITES } from './site';
import { LOCATION_TEMPLATES } from './location-template';

export const LOCATIONS: Array<Location> = [Location.getInstanceFromObject({
    id: 'bc8b3a23-4bb0-4d90-9d66-245858d8534c',
    name: 'Bangalore',
    locationTemplate: LOCATION_TEMPLATES[0].id,
    site: SITES[0].id,
})];
