import State, { ActionType } from '../models/state';
import { ToDoStatus } from '../models/to-do';

export const STATES: Array<State> = [{
    id: '61aed77b-ba9c-47c3-b663-a5656bf73a17',
    name: 'Initiated',
    status: ToDoStatus.CREATED,
    canDispatcherExecute: true,
    canTechnicianExecute: true,
    actions: [{
        name: 'Assign',
        type: ActionType.STATE_TRANSITION,
        context: '3d22c049-4089-4c8f-9af0-fe21655e2342'
    }],
    customAttributes: [],
}, {
    id: '3d22c049-4089-4c8f-9af0-fe21655e2342',
    name: 'Assigned',
    status: ToDoStatus.ASSIGNED,
    canDispatcherExecute: true,
    canTechnicianExecute: true,
    actions: [{
        name: 'Reject',
        type: ActionType.STATE_TRANSITION,
        context: '61aed77b-ba9c-47c3-b663-a5656bf73a17',
    }, {
        name: 'Accept',
        type: ActionType.STATE_TRANSITION,
        context: 'dfcc4634-1dd0-472e-89ee-7257929aa8ff'
    }],
    customAttributes: [],
}, {
    id: 'dfcc4634-1dd0-472e-89ee-7257929aa8ff',
    name: 'Accepted',
    status: ToDoStatus.ACCEPTED,
    canDispatcherExecute: true,
    canTechnicianExecute: true,
    actions: [{
        name: 'Start Work',
        type: ActionType.STATE_TRANSITION,
        context: '8ed4fd6b-26e4-40ea-a37a-80ac7b41aa0b'
    }],
    customAttributes: [],
}, {
    id: '8ed4fd6b-26e4-40ea-a37a-80ac7b41aa0b',
    name: 'In Progress',
    status: ToDoStatus.IN_PROGRESS,
    canDispatcherExecute: false,
    canTechnicianExecute: true,
    actions: [{
        name: 'Complete',
        type: ActionType.STATE_TRANSITION,
        context: '5f11c2b5-de29-4ed0-a77d-8b14b01a84a9'
    }],
    customAttributes: [],
}, {
    id: '5f11c2b5-de29-4ed0-a77d-8b14b01a84a9',
    name: 'Completed',
    status: ToDoStatus.COMPLETED,
    canDispatcherExecute: true,
    canTechnicianExecute: true,
    actions: [],
    customAttributes: [],
}];
