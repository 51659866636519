import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlService } from './url.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    token: string;
    myId: string;
    loginSubject: BehaviorSubject<string>;

    constructor(private urlService: UrlService, private http: HttpClient) {
        if (window.localStorage.getItem('token')) {
            this.token = window.localStorage.getItem('token');
        }

        if (window.localStorage.getItem('myId')) {
            this.myId = window.localStorage.getItem('myId');
        }

        this.loginSubject = new BehaviorSubject(this.token);
    }

    async login(username: string, password: string) {
        return this.http.post(this.urlService.getBaseURL() + 'api-auth/', {
            username,
            password,
        }).toPromise().then(data => {
            this.token = (data as any).token;
            window.localStorage.setItem('token', this.token);

            
            const headers = new HttpHeaders({
                Authorization: `Token ${this.token}`,
            });
            const httpOptions = {
                headers,
            };

            return this.http.get(this.urlService.getBaseURL() + 'my-id/', httpOptions).toPromise().then(data => {
                this.myId = (data as any).id;
                window.localStorage.setItem('myId', String(this.myId));
                
                this.loginSubject.next(this.myId);
            });
        }).catch(error => {
            throw new Error('Incorrect username or password');
        });
    }

    getAuthenticationObservable() {
        return this.loginSubject.asObservable();
    }

    logout() {
        this.token = undefined;
        this.myId = undefined;
        
        window.localStorage.removeItem('myId');
        window.localStorage.removeItem('token');
    }

    async resetPassword(otp: string, newPassword: string) {
        if (otp === '123456') {
            return true;
        } else {
            throw new Error('Incorrect OTP');
        }
    }

    getToken() {
        return this.token;
    }

    storeToken(token: string) {
        this.token = token;
    }

    getMyId() {
        return this.myId;
    }

    storeMyId(myId: string) {
        this.myId = myId;
    }

    isAuthenticated() {
        return !!this.token;
    }
}
