import { Injectable } from '@angular/core';
import { StorageWrapper } from 'src/shared/storage/storage-wrapper';
import Location from 'src/shared/models/location';
import { LOCATIONS } from 'src/shared/mocks/location';
import { AsyncLocalStorage } from 'src/shared/storage/storable';
import { AuthenticationService } from 'src/shared/services/authentication.service';
import { UrlService } from 'src/shared/services/url.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    storage: AsyncLocalStorage;
    storageWrapper: StorageWrapper<Location>;

    constructor(
        authenticationService: AuthenticationService,
        urlService: UrlService,
        http: HttpClient
    ) {
        this.storage = new AsyncLocalStorage();
        this.storageWrapper = new StorageWrapper(
            'location_entries',
            'locations',
            Location.getInstanceFromObject,
            this.storage,
            authenticationService,
            urlService,
            http,
            LOCATIONS
        );
    }

    async getLocationEntries(force = false) {
        return this.storageWrapper.getEntries(force);
    }

    getLocationEntriesObservable() {
        return this.storageWrapper.getEntriesObservable();
    }

    async addLocation(newLocation: Location) {
        return this.storageWrapper.addEntry(newLocation);
    }

    async updateLocation(location: Location) {
        return this.storageWrapper.updateEntry(location);
    }

    async deleteLocation(deletedLocationId: string) {
        return this.storageWrapper.softDeleteEntry(deletedLocationId);
    }

    async getLocationById(id: string) {
        return this.storageWrapper.getEntryById(id);
    }

    async getLocationByName(name: string, siteId: string) {
        const assets = await this.getLocationEntries();
        return assets.find(location => location.site === siteId && location.name === name);
    }

    getLocationEntryObservable(id: string) {
        return this.storageWrapper.getEntryObservable(id);
    }

    async refreshEntries() {
        return this.storageWrapper.refreshEntries();
    }

    async pushDataToServer() {
        return this.storageWrapper.pushDataToServer();
    }
}
