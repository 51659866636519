import { Component, OnInit, Input } from '@angular/core';
import { LocationService } from '../services/location.service';
import Location from 'src/shared/models/location';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.page.html',
  styleUrls: ['./select-location.page.scss'],
})
export class SelectLocationPage implements OnInit {
  @Input() inspectionId: string;
  selectedLocationId: string;
  locations: Array<Location> = [];

  constructor(locationService: LocationService, private modalController: ModalController) {
    locationService.getLocationEntriesObservable().subscribe(locations => this.locations = locations);
  }

  selectLocation(id: string) {
    this.selectedLocationId = id;
  }

  select() {
    this.modalController.dismiss(this.selectedLocationId);
  }

  cancel() {
    this.modalController.dismiss();
  }

  ngOnInit() {
  }

}
