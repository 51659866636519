import ToDoTemplate from "../models/to-do-template";
import { STATES } from './state';
import { UPS_QUESTIONS } from './question';
import { DEPARTMENTS } from './department';
import { ASSET_TEMPLATES } from './asset-template';

export const TO_DO_TEMPLATES: Array<ToDoTemplate> = [ToDoTemplate.getInstanceFromObject({
    id: '0931dabd-a320-41b5-9ca5-4101650db017',
    name: 'Template 1',
    assetTemplate: ASSET_TEMPLATES[0].id,
    department: DEPARTMENTS[0].id,
    customAttributes: [{
        id: 'b832584d-a9f6-4d69-8c2d-82dd9ac9911d',
        name: 'Priority',
        type: 'SINGLE SELECT',
        options: [{
            id: '73c6aaaa-2306-4116-830e-a4cf15e77eaa',
            name: 'High',
        }, {
            id: '7b4d9145-7544-4aa6-828c-a275bf61055e',
            name: 'Medium',
        }, {
            id: 'b5039d84-b687-4c88-8312-483479cc226e',
            name: 'Low',
        }],
        isRequired: true,
        isInTable: true,
    }],
    states: STATES,
    initialStateId: STATES[0].id,
    questions: UPS_QUESTIONS,
})];
