import Employee from '../models/employee';
import { EMPLOYEE_TEMPLATES } from './employee-template';
import { DEPARTMENTS } from './department';

export const EMPLOYEES: Array<Employee> = [Employee.getInstanceFromObject({
    id: '432bbadf-7fea-4a97-b5fa-6aff9dcc5ca6',
    name: 'Employee 1',
    department: DEPARTMENTS[0].id,
    employeeTemplate: EMPLOYEE_TEMPLATES[0].id,
    userId: '391b9756-aa72-4f36-b595-977f8b1609ed',
})];
