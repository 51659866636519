export function isUUID(value: any) {

    if (typeof value !== 'string') {
        return false;
    }

    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value);
}

export function isEmailValid(email: string) {
    const simpleEmailRegexPattern = '.+\\@.+\\..+';

    const emailRegex = new RegExp(simpleEmailRegexPattern);

    return emailRegex.test(email);
}

export interface Option {
    label: string;
    value: string;
}
