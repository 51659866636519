import CustomAttribute, { validateCustomAttributeObject } from './custom-attribute';
import * as uuid from 'uuid';
import { isUUID } from '../utilities';
import { Checklist, validateQuestionObject, getQuestionFromPlainObject } from './question';

export default class AssetTemplate {

    static defaultValues = {
        archived: false,
        customAttributes: [],
        checklists: [],
    };

    id: string;
    name: string;
    archived: boolean;
    department: string;
    customAttributes: Array<CustomAttribute>;
    checklists: Array<Checklist>;

    static getInstanceFromObject(initializationData: any) {

        if (typeof initializationData !== 'object' || Array.isArray(initializationData) || initializationData === null) {
            throw new Error('The initialization data must be an object');
        }

        // Seed all the default values into the object if they don't exist
        for (const defaultKey in AssetTemplate.defaultValues) {
            if (!initializationData.hasOwnProperty(defaultKey)) {
                initializationData[defaultKey] = AssetTemplate.defaultValues[defaultKey];
            }
        }

        // Begin validation

        // ID validation
        if (initializationData.hasOwnProperty('id')) {

            if (!isUUID(initializationData.id)) {
                throw new Error('Invalid ID');
            }
        }

        // Name validation
        if (!initializationData.hasOwnProperty('name')) {
            throw new Error('The name is required');
        }

        if (typeof initializationData.name !== 'string' || initializationData.name.trim().length === 0) {
            throw new Error('Invalid name');
        }

        // Department validation
        if (!initializationData.hasOwnProperty('department')) {
            throw new Error('The department is required');
        }

        if (typeof initializationData.department !== 'string' || initializationData.department.trim().length === 0 || !isUUID(initializationData.department)) {
            throw new Error('Invalid department');
        }

        // Asset Custom attributes validation
        if (!Array.isArray(initializationData.customAttributes)) {
            throw new Error('Custom Attributes must be an array');
        }

        for (const customAttribute of initializationData.customAttributes) {
            validateCustomAttributeObject(customAttribute);
        }

        // Asset checklists validation
        if (!Array.isArray(initializationData.checklists)) {
            throw new Error('Checklists must be an array');
        }

        for (const checklist of initializationData.checklists) {

            // ID validation
            if (checklist.hasOwnProperty('id')) {

                if (!isUUID(checklist.id)) {
                    throw new Error('Invalid ID');
                }
            }

            // Name validation
            if (!checklist.hasOwnProperty('name')) {
                throw new Error('The name is required');
            }
    
            if (typeof checklist.name !== 'string' || checklist.name.trim().length === 0) {
                throw new Error('Invalid name');
            }

            // Questions validation
            if (!checklist.hasOwnProperty('questions')) {
                throw new Error('Questions are required');
            }

            if (!Array.isArray(checklist.questions)) {
                throw new Error('Questions must be an array');
            }

            for (const question of checklist.questions) {
                validateQuestionObject(question);
            }
        }

        // End validation


        // Instantiation
        const newInstance = new AssetTemplate();

        if ('id' in initializationData) {
            newInstance.id = initializationData.id;
        } else {
            newInstance.id = uuid.v4();
        }

        newInstance.archived = initializationData.archived;

        newInstance.name = initializationData.name;

        newInstance.customAttributes = initializationData.customAttributes;
        newInstance.department = initializationData.department;
        newInstance.checklists = initializationData.checklists.map(checklistObject => {
            return {
                id: checklistObject.id ? checklistObject.id : uuid.v4(),
                name: checklistObject.name,
                questions: checklistObject.questions.map(questionObject => getQuestionFromPlainObject(questionObject)),
            };
        })

        return newInstance;
    }

    convertToPlainObject() {
        return {
            ...this as AssetTemplate,
        };
    }

    update(updateObject: any) {
        const { id, ...otherParams } = updateObject;

        for (const classKey in this) {
            if (otherParams.hasOwnProperty(classKey)) {
                this[classKey] = otherParams[classKey];
            }
        }
    }
}
