import CustomAttribute from './custom-attribute';
import { isUUID } from '../utilities';
import * as uuid from 'uuid';

export default class SiteTemplate {

    static defaultValues = {
        archived: false,
        customAttributes: [],
    };
    
    id: string;
    archived: boolean;
    name: string;
    customAttributes: Array<CustomAttribute>;

    static getInstanceFromObject(initializationData: any) {

        if (typeof initializationData !== 'object') {
            throw new Error('The initialization data must be an object');
        }

        // Seed all the default values into the object if they don't exist
        for (const defaultKey in SiteTemplate.defaultValues) {
            if (!initializationData.hasOwnProperty(defaultKey)) {
                initializationData[defaultKey] = SiteTemplate.defaultValues[defaultKey];
            }
        }

        // Begin validation

        // ID validation
        if (initializationData.hasOwnProperty('id')) {

            if (!isUUID(initializationData.id)) {
                throw new Error('Invalid ID');
            }
        }

        // Name validation
        if (!initializationData.hasOwnProperty('name')) {
            throw new Error('The name is required');
        }

        if (typeof initializationData.name !== 'string' || initializationData.name.trim().length === 0) {
            throw new Error('Invalid name');
        }

        // Custom attributes validation
        if (!Array.isArray(initializationData.customAttributes)) {
            throw new Error('Custom Attributes must be an array');
        }

        for (const customAttribute of initializationData.customAttributes) {

            if (!customAttribute.hasOwnProperty('id')) {
                throw new Error('The custom attribute must have a value for the ID');
            }

            if (!isUUID(customAttribute.id)) {
                throw new Error('The custom attribute ID is invalid');
            }

            if (!customAttribute.hasOwnProperty('name')) {
                throw new Error('The custom attribute must have a value for the name');
            }

            if (typeof customAttribute.name !== 'string' || customAttribute.name.trim().length === 0) {
                throw new Error('The custom attribute name is invalid');
            }

            if (!customAttribute.hasOwnProperty('isRequired')) {
                throw new Error('The custom attribute must have a value for the is required field');
            }

            if (typeof customAttribute.isRequired !== 'boolean') {
                throw new Error('The custom attribute is-required is invalid');
            }

            if (!customAttribute.hasOwnProperty('isInTable')) {
                throw new Error('The custom attribute must have a value for the is in table field');
            }

            if (typeof customAttribute.isInTable !== 'boolean') {
                throw new Error('The custom attribute is-in-table is invalid');
            }

            // Custom attribute options validation
            if (!Array.isArray(customAttribute.options)) {
                throw new Error('Custom Attributes options must be an array');
            }

            for (const customAttributeOption of customAttribute.options) {

                if (!customAttributeOption.hasOwnProperty('id')) {
                    throw new Error('The custom attribute option must have a value for the ID');
                }

                if (!isUUID(customAttributeOption.id)) {
                    throw new Error('The custom attribute option ID is invalid');
                }

                if (!customAttributeOption.hasOwnProperty('name')) {
                    throw new Error('The custom attribute option must have a value for the name');
                }

                if (typeof customAttributeOption.name !== 'string' || customAttributeOption.name.trim().length === 0) {
                    throw new Error('The custom attribute option name is invalid');
                }

            }

        }

        // End validation



        // Instantiation
        const newInstance = new SiteTemplate();

        if ('id' in initializationData) {
            newInstance.id = initializationData.id;
        } else {
            newInstance.id = uuid.v4();
        }

        newInstance.archived = initializationData.archived;

        newInstance.name = initializationData.name;
        newInstance.customAttributes = initializationData.customAttributes;

        return newInstance;
    }

    convertToPlainObject() {
        return {
            ...this as SiteTemplate,
        };
    }

    update(updateObject: any) {
        const { id, ...otherParams } = updateObject;

        for (const classKey in this) {
            if (otherParams.hasOwnProperty(classKey)) {
                this[classKey] = otherParams[classKey];
            }
        }
    }
}