import { isUUID } from '../utilities';
import * as uuid from 'uuid';
import { Storable } from '../storage/storable';
import { CustomAttributeValues } from './custom-attribute';

export default class Asset implements Storable {

    static defaultValues = {
        archived: false,
        customAttributeValues: {},
    };

    id: string;
    readableId?: string;
    archived: boolean;
    name: string;
    assetTemplate: string;
    location: string;
    customAttributeValues: CustomAttributeValues;

    static getInstanceFromObject(initializationData: any) {

        if (typeof initializationData !== 'object') {
            throw new Error('The initialization data must be an object');
        }

        // Seed all the default values into the object if they don't exist
        for (const defaultKey in Asset.defaultValues) {
            if (!initializationData.hasOwnProperty(defaultKey)) {
                initializationData[defaultKey] = Asset.defaultValues[defaultKey];
            }
        }

        // Begin validation

        // ID validation
        if (initializationData.hasOwnProperty('id')) {

            if (!isUUID(initializationData.id)) {
                throw new Error('Invalid ID');
            }
        }

        // Name validation
        if (!initializationData.hasOwnProperty('name')) {
            throw new Error('The name is required');
        }

        if (typeof initializationData.name !== 'string' || initializationData.name.trim().length === 0) {
            throw new Error('Invalid name');
        }

        // Template validation
        if (!initializationData.hasOwnProperty('assetTemplate')) {
            throw new Error('The template is required');
        }

        if (typeof initializationData.assetTemplate !== 'string' || initializationData.assetTemplate.trim().length === 0 || !isUUID(initializationData.assetTemplate)) {
            throw new Error('Invalid asset template');
        }

        // Location validation
        if (!initializationData.hasOwnProperty('location')) {
            throw new Error('The location is required');
        }

        if (typeof initializationData.location !== 'string' || initializationData.location.trim().length === 0 || !isUUID(initializationData.location)) {
            throw new Error('Invalid location');
        }


        // Custom attributes validation
        if (typeof initializationData.customAttributeValues !== 'object') {
            throw new Error('Custom attribute values must be an object');
        }

        for (const customAttributeId in initializationData.customAttributeValues) {
            if (initializationData.customAttributeValues.hasOwnProperty(customAttributeId)) {
                const value = initializationData.customAttributeValues[customAttributeId];

                if (Array.isArray(value) && !value.every(element => typeof element === 'string')) {
                    throw new Error('Invalid format for custom attribute value - the array has non-string elements');
                } else if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'undefined') {
                    throw new Error('Unsupported value type for custom attribute');
                }
            }
        }

        // End validation



        // Instantiation
        const newInstance = new Asset();

        if ('id' in initializationData) {
            newInstance.id = initializationData.id;
        } else {
            newInstance.id = uuid.v4();
        }

        if (initializationData.readableId) {
            newInstance.readableId = initializationData.readableId;
        }

        if (initializationData.location) {
            newInstance.location = initializationData.location;
        }

        newInstance.archived = initializationData.archived;

        newInstance.name = initializationData.name;
        newInstance.assetTemplate = initializationData.assetTemplate;
        newInstance.customAttributeValues = initializationData.customAttributeValues;

        return newInstance;
    }

    convertToPlainObject() {
        return {
            ...this as Asset,
        };
    }

    update(updateObject: any) {
        const { id, ...otherParams } = updateObject;

        for (const classKey of Object.keys(updateObject)) {
            if (otherParams.hasOwnProperty(classKey)) {
                this[classKey] = otherParams[classKey];
            }
        }
    }
}
