import * as uuid from 'uuid';
import { isUUID } from '../utilities';
import { CustomAttributeValues } from './custom-attribute';

export default class Employee {

    static defaultValues = {
        archived: false,
        isAppUser: false,
        sites: [],
        locations: [],
        assets: [],
        customAttributeValues: {},
    };

    id: string;
    name: string;
    employeeTemplate: string;
    department: string;
    email?: string;
    phone?: string;

    sites: Array<string>;
    locations: Array<string>;
    assets: Array<string>;

    userId?: string;
    archived: boolean;
    
    customAttributeValues: CustomAttributeValues;

    static getInstanceFromObject(initializationData: any) {

        if (typeof initializationData !== 'object') {
            throw new Error('The initialization data must be an object');
        }

        // Seed all the default values into the object if they don't exist
        for (const defaultKey in Employee.defaultValues) {
            if (!initializationData.hasOwnProperty(defaultKey)) {
                initializationData[defaultKey] = Employee.defaultValues[defaultKey];
            }
        }

        // Begin validation

        // ID validation
        if (initializationData.hasOwnProperty('id')) {

            if (!isUUID(initializationData.id)) {
                throw new Error('Invalid ID');
            }
        }

        // Name validation
        if (!initializationData.hasOwnProperty('name')) {
            throw new Error('The name is required');
        }

        if (typeof initializationData.name !== 'string' || initializationData.name.trim().length === 0) {
            throw new Error('Invalid name');
        }

        // Email validation
        if (initializationData.hasOwnProperty('email') && !!initializationData.email) {

            if (typeof initializationData.email !== 'string' || initializationData.email.trim().length === 0) {
                throw new Error('Invalid email');
            }
        }

        // Phone validation
        if (initializationData.hasOwnProperty('phone') && !!initializationData.phone) {

            if (typeof initializationData.phone !== 'string' || initializationData.phone.trim().length === 0) {
                throw new Error('Invalid phone');
            }
        }

        // Type validation
        if (!initializationData.hasOwnProperty('employeeTemplate') || typeof initializationData.employeeTemplate === 'undefined' ||
            initializationData.employeeTemplate === null) {
            throw new Error('The employee template is required');
        }

        if (!isUUID(initializationData.employeeTemplate)) {
            throw new Error('Invalid employee template ID');
        }

        // Department validation
        if (!initializationData.hasOwnProperty('department') || typeof initializationData.department === 'undefined' ||
            initializationData.department === null) {
            throw new Error('The department is required');
        }

        if (!isUUID(initializationData.department)) {
            throw new Error('Invalid department ID');
        }

        // User ID validation
        if (initializationData.hasOwnProperty('userId') && !!initializationData.userId) {

            if (!isUUID(initializationData.userId)) {
                throw new Error('Invalid user ID');
            }
        }

        // Sites validation
        if (!Array.isArray(initializationData.sites)) {
            throw new Error('Sites must be an array');
        }

        if (!initializationData.sites.every(site => !!site && typeof site === 'string' && isUUID(site))) {
            throw new Error('Invalid site information')
        }

        // Locations validation
        if (!Array.isArray(initializationData.locations)) {
            throw new Error('Locations must be an array');
        }

        if (!initializationData.locations.every(location => !!location && typeof location === 'string' && isUUID(location))) {
            throw new Error('Invalid location information')
        }

        // Assets validation
        if (!Array.isArray(initializationData.assets)) {
            throw new Error('Assets must be an array');
        }

        if (!initializationData.assets.every(asset => !!asset && typeof asset === 'string' && isUUID(asset))) {
            throw new Error('Invalid asset information')
        }

        // Custom attributes validation
        if (typeof initializationData.customAttributeValues !== 'object') {
            throw new Error('Custom attribute values must be an object');
        }

        for (const customAttributeId in initializationData.customAttributeValues) {
            if (initializationData.customAttributeValues.hasOwnProperty(customAttributeId)) {
                const value = initializationData.customAttributeValues[customAttributeId];

                if (Array.isArray(value) && !value.every(element => typeof element === 'string')) {
                    throw new Error('Invalid format for custom attribute value - the array has non-string elements');
                } else if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'undefined') {
                    throw new Error('Unsupported value type for custom attribute');
                }
            }
        }

        // End validation



        // Instantiation
        const newInstance = new Employee();

        if ('id' in initializationData) {
            newInstance.id = initializationData.id;
        } else {
            newInstance.id = uuid.v4();
        }

        newInstance.archived = initializationData.archived;

        newInstance.name = initializationData.name;
        newInstance.department = initializationData.department;
        newInstance.employeeTemplate = initializationData.employeeTemplate;

        newInstance.assets = initializationData.assets;
        newInstance.sites = initializationData.sites;
        newInstance.locations = initializationData.locations;

        if (!!initializationData.userId) {
            newInstance.userId = initializationData.userId;
        }

        if (!!initializationData.phone) {
            newInstance.phone = initializationData.phone;
        }

        if (!!initializationData.email) {
            newInstance.email = initializationData.email;
        }

        newInstance.customAttributeValues = initializationData.customAttributeValues;

        return newInstance;
    }

    convertToPlainObject() {
        return {
            ...this as Employee,
        };
    }

    update(updateObject: any) {
        const { id, ...otherParams } = updateObject;

        for (const classKey in this) {
            if (otherParams.hasOwnProperty(classKey)) {
                this[classKey] = otherParams[classKey];
            }
        }
    }
}
