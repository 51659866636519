import { Injectable } from '@angular/core';
import { StorageWrapper } from 'src/shared/storage/storage-wrapper';
import Asset from 'src/shared/models/asset';
import { ASSETS } from 'src/shared/mocks/asset';
import { AsyncLocalStorage } from 'src/shared/storage/storable';
import { AuthenticationService } from 'src/shared/services/authentication.service';
import { UrlService } from 'src/shared/services/url.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AssetService {
    storage: AsyncLocalStorage;
    storageWrapper: StorageWrapper<Asset>;

    constructor(
        authenticationService: AuthenticationService,
        urlService: UrlService,
        http: HttpClient
    ) {
        this.storage = new AsyncLocalStorage();
        this.storageWrapper = new StorageWrapper(
            'asset_entries',
            'assets',
            Asset.getInstanceFromObject,
            this.storage,
            authenticationService,
            urlService,
            http,
            ASSETS
        );
    }

    async getAssetEntries(force = false) {
        return this.storageWrapper.getEntries(force);
    }

    getAssetEntriesObservable() {
        return this.storageWrapper.getEntriesObservable();
    }

    async addAsset(newAsset: Asset) {
        return this.storageWrapper.addEntry(newAsset);
    }

    async updateAsset(asset: Asset) {
        return this.storageWrapper.updateEntry(asset);
    }

    async deleteAsset(deletedAssetId: string) {
        return this.storageWrapper.softDeleteEntry(deletedAssetId);
    }

    async getAssetById(id: string) {
        return this.storageWrapper.getEntryById(id);
    }

    async getAssetByReadableId(id: string) {
        const assets = await this.getAssetEntries();
        return assets.find(asset => asset.readableId === id);
    }

    getAssetEntryObservable(id: string) {
        return this.storageWrapper.getEntryObservable(id);
    }

    async refreshEntries() {
        return this.storageWrapper.refreshEntries();
    }

    async pushDataToServer() {
        return this.storageWrapper.pushDataToServer();
    }
}
