import { Component, OnInit, Input } from '@angular/core';
import { AssetService } from '../services/asset.service';
import Asset from 'src/shared/models/asset';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-asset',
  templateUrl: './select-asset.page.html',
  styleUrls: ['./select-asset.page.scss'],
})
export class SelectAssetPage implements OnInit {
  @Input() inspectionId: string;
  selectedAssetId: string;
  assets: Array<Asset> = [];

  constructor(assetService: AssetService, private modalController: ModalController) {
    assetService.getAssetEntriesObservable().subscribe(assets => this.assets = assets);
  }

  selectAsset(id: string) {
    this.selectedAssetId = id;
  }

  select() {
    this.modalController.dismiss(this.selectedAssetId);
  }

  cancel() {
    this.modalController.dismiss();
  }

  ngOnInit() {
  }

}
