import * as uuid from 'uuid';
import { isUUID } from '../utilities';
import { CustomAttributeValues } from './custom-attribute';
import State, { StateSnapshot } from './state';
import moment from 'moment';
import { Storable } from '../storage/storable';
import { Answer, ActionSnapshot, UserActionReport, UserActionPrompt } from './question';

export enum ToDoStatus {
    CREATED = 'C',
    ASSIGNED = 'A',
    ACCEPTED = 'AC',
    IN_PROGRESS = 'I',
    COMPLETED = 'CO',
    CANCELLED = 'CA',
}

export enum TaskType {
    ROUTINE = 'RO',
    REACTIVE = 'RE',
}

export enum ToDoEventType {
    STATE = 'S',
    TRIGGERED_ACTION = 'T',
    USER_ACTION_PROMPT = 'U',
}

interface StateEvent {
    type: ToDoEventType.STATE,
    payload: StateSnapshot,
};

interface TriggeredActionEvent {
    type: ToDoEventType.TRIGGERED_ACTION,
    payload: ActionSnapshot,
}

interface UserActionPromptEvent {
    type: ToDoEventType.USER_ACTION_PROMPT,
    payload: UserActionReport,
}

export type ToDoEvent = StateEvent | TriggeredActionEvent | UserActionPromptEvent;

export default class ToDo implements Storable {

    static defaultValues = {
        archived: false,
        status: ToDoStatus.CREATED,
        taskType: TaskType.ROUTINE,
        createdTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
        customAttributeValues: {},
        events: [],
        answers: [],
        assignedUser: undefined,
    };

    id: string;
    readableId?: string;
    archived: boolean;
    description?: string;
    status: ToDoStatus;
    taskType: TaskType;
    createdTime: string;
    toDoTemplate: string;
    startTime?: string;
    dueTime: string;
    asset?: string;
    location?: string;
    configuration?: string;
    assignedUser: string|undefined;

    completedBy?: string;
    customAttributeValues: CustomAttributeValues;
    currentStateId?: string;
    events: Array<ToDoEvent>;
    answers: Array<Answer>;

    triggeringToDo: string;

    static getInstanceFromObject(initializationData: any) {

        if (typeof initializationData !== 'object') {
            throw new Error('The initialization data must be an object');
        }

        // Seed all the default values into the object if they don't exist
        for (const defaultKey in ToDo.defaultValues) {
            if (!initializationData.hasOwnProperty(defaultKey)) {
                initializationData[defaultKey] = ToDo.defaultValues[defaultKey];
            }
        }

        // Begin validation

        // ID validation
        if (initializationData.hasOwnProperty('id')) {

            if (!isUUID(initializationData.id)) {
                throw new Error('Invalid ID');
            }
        }

        if (initializationData.hasOwnProperty('readableId') && !!initializationData.readableId) {

            if (isNaN(Number(initializationData.readableId))) {
                throw new Error('Invalid readable ID');
            }
        }

        // Description validation

        if (initializationData.hasOwnProperty('description') && !!initializationData.description) {

            if (typeof initializationData.description !== 'string') {
                throw new Error('Invalid description');
            }
        }

        const statuses = Object.keys(ToDoStatus).map(status => ToDoStatus[status]);

        // Status validation
        if (!statuses.includes(initializationData.status)) {
            throw new Error('Invalid status');
        }

        const taskTypes = Object.keys(TaskType).map(type => TaskType[type]);

        // Task type validation
        if (!taskTypes.includes(initializationData.taskType)) {
            throw new Error('Invalid task type');
        }

        // Start time validation
        if (initializationData.startTime) {
            if (!moment(initializationData.startTime, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
                throw new Error('The due time is invalid');
            }
        }

        // Due time validation
        if (!initializationData.hasOwnProperty('dueTime') || typeof initializationData.dueTime === 'undefined' ||
            initializationData.dueTime === null) {
            throw new Error('The due time is required');
        }

        if (!moment(initializationData.dueTime, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
            throw new Error('The due time is invalid');
        }

        // Created time validation
        if (initializationData.hasOwnProperty('createdTime') && typeof initializationData.createdTime !== 'undefined'
            && initializationData.createdTime !== null) {
            if (!moment(initializationData.createdTime, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
                throw new Error('The created time is invalid');
            }
        } else {
            initializationData.createdTime = moment().format('YYYY-MM-DDTHH:mm:ss');
        }


        // Asset validation
        if (initializationData.hasOwnProperty('asset') && !!initializationData.asset) {

            if (!isUUID(initializationData.asset)) {
                throw new Error('Invalid asset ID');
            }
        }

        // Location validation
        if (initializationData.hasOwnProperty('location') && !!initializationData.location) {

            if (!isUUID(initializationData.location)) {
                throw new Error('Invalid location ID');
            }
        }

        // Type validation
        if (!initializationData.hasOwnProperty('toDoTemplate') || typeof initializationData.toDoTemplate === 'undefined' ||
            initializationData.toDoTemplate === null) {
            throw new Error('The to do template is required');
        }

        if (!isUUID(initializationData.toDoTemplate)) {
            throw new Error('Invalid to do template ID');
        }

        // User validation
        if (initializationData.hasOwnProperty('assignedUser') && initializationData.assignedUser) {

            if (!isUUID(initializationData.assignedUser)) {
                throw new Error('Invalid user ID');
            }
        }

        // Completed by validation
        if (initializationData.hasOwnProperty('completedBy') && initializationData.completedBy) {

            if (!isUUID(initializationData.completedBy)) {
                throw new Error('Invalid completing employee ID');
            }
        }

        // Configuration validation
        if (initializationData.hasOwnProperty('configuration') && typeof initializationData.configuration !== 'undefined' &&
            initializationData.configuration !== null) {

            if (typeof initializationData.configuration !== 'undefined' && !isUUID(initializationData.configuration)) {
                throw new Error('Invalid user ID');
            }
        }

        // Custom attributes validation
        if (typeof initializationData.customAttributeValues !== 'object') {
            throw new Error('Custom attribute values must be an object');
        }

        for (const customAttributeId in initializationData.customAttributeValues) {
            if (initializationData.customAttributeValues.hasOwnProperty(customAttributeId)) {
                const value = initializationData.customAttributeValues[customAttributeId];

                if (Array.isArray(value) && !value.every(element => typeof element === 'string')) {
                    throw new Error('Invalid format for custom attribute value - the array has non-string elements');
                } else if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'undefined') {
                    throw new Error('Unsupported value type for custom attribute');
                }
            }
        }

        // Current state validation
        if (initializationData.hasOwnProperty('currentStateId')) {

            if (!isUUID(initializationData.currentStateId)) {
                throw new Error('Invalid state ID');
            }
        }

        // Events validation
        const eventTypes = Object.keys(ToDoEventType).map(type => ToDoEventType[type]);

        if (!Array.isArray(initializationData.events)) {
            throw new Error('Events must be an array');
        }

        for (const event of initializationData.events) {
            if (!event.hasOwnProperty('type')) {
                throw new Error('Event type is required');
            }

            if (!event.hasOwnProperty('payload')) {
                throw new Error('Event payload is required');
            }

            // Type validation
            if (!eventTypes.includes(event.type)) {
                throw new Error('Invalid type');
            }

            switch (event.type) {
                case ToDoEventType.STATE:
                    const stateSnapshot = event.payload;

                    if (!stateSnapshot.hasOwnProperty('stateId')) {
                        throw new Error('The state snapshot must have a value for the state ID');
                    }
        
                    if (!isUUID(stateSnapshot.stateId)) {
                        throw new Error('The state snapshot state ID is invalid');
                    }
        
                    if (!stateSnapshot.hasOwnProperty('transitionTime')) {
                        throw new Error('The state snapshot must have a value for the transition time');
                    }
        
                    if (!moment(stateSnapshot.transitionTime, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
                        throw new Error('The snapshot transition time is invalid');
                    }
        
                    if (stateSnapshot.hasOwnProperty('customAttributeValues')) {
                        if (typeof stateSnapshot.customAttributeValues !== 'object') {
                            throw new Error('The custom attribute values holder must be an object');
                        }
        
                        for (const customAttributeId in stateSnapshot.customAttributeValues) {
                            if (stateSnapshot.customAttributeValues.hasOwnProperty(customAttributeId)) {
                                const value = stateSnapshot.customAttributeValues[customAttributeId];
        
                                if (Array.isArray(value) && !value.every(element => typeof element === 'string')) {
                                    throw new Error('Invalid format for custom attribute value - the array has non-string elements');
                                } else if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'undefined') {
                                    throw new Error('Unsupported value type for custom attribute');
                                }
                            }
                        }
                    }

                    break;
                
                case ToDoEventType.TRIGGERED_ACTION:
                    const action = event.payload;

                    if (!action.hasOwnProperty('actionId')) {
                        throw new Error('The action must have a value for the action ID');
                    }
        
                    if (!isUUID(action.actionId)) {
                        throw new Error('The action\'s action ID is invalid');
                    }
        
                    if (!action.hasOwnProperty('actionTime')) {
                        throw new Error('The action must have a value for the action time');
                    }
        
                    if (!moment(action.actionTime, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
                        throw new Error('The action time is invalid');
                    }
        
                    if (!action.hasOwnProperty('context')) {
                        throw new Error('The action must have a value for the action time');
                    }
        
                    if (typeof action.context !== 'string') {
                        throw new Error('The context must be a string');
                    }
                    break;
                
                case ToDoEventType.USER_ACTION_PROMPT:
                    const actionPrompt = event.payload;

                    if (!actionPrompt.hasOwnProperty('userActionId')) {
                        throw new Error('The action must have a value for the action ID');
                    }

                    if (!isUUID(actionPrompt.userActionId)) {
                        throw new Error('The action\'s action ID is invalid');
                    }

                    if (!actionPrompt.hasOwnProperty('actionTime')) {
                        throw new Error('The action must have a value for the action time');
                    }

                    if (!moment(actionPrompt.actionTime, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
                        throw new Error('The action time is invalid');
                    }

                    if (!actionPrompt.hasOwnProperty('context')) {
                        throw new Error('The action must have a value for the action time');
                    }

                    if (typeof actionPrompt.context !== 'string') {
                        throw new Error('The context must be a string');
                    }

                    break;
                
                default:
                    throw new Error('Unknown event type');
            }
        }

        // Answers validation
        if (!Array.isArray(initializationData.answers)) {
            throw new Error('Answers must be an array');
        }

        for (const answer of initializationData.answers) {

            if (!answer.hasOwnProperty('questionId')) {
                throw new Error('The answer must have a value for the question ID');
            }

            if (!isUUID(answer.questionId)) {
                throw new Error('The answer\'s question ID is invalid');
            }

            if (!answer.hasOwnProperty('answerTime')) {
                throw new Error('The answer must have a value for the answer time');
            }

            if (!moment(answer.answerTime, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
                throw new Error('The answer time is invalid');
            }

            if (Array.isArray(answer.value) && !answer.value.every(element => typeof element === 'string')) {
                throw new Error('Invalid format for answer value - the array has non-string elements');
            } else if (typeof answer.value !== 'string' && typeof answer.value !== 'number' && typeof answer.value !== 'undefined' && typeof answer.value !== 'boolean') {
                throw new Error('Unsupported value type for answer');
            }

        }

        // Triggering TODo validation
        if (initializationData.hasOwnProperty('triggeringToDo') && !!initializationData.triggeringToDo) {

            if (!isUUID(initializationData.triggeringToDo)) {
                throw new Error('Invalid triggering ToDo');
            }
        }

        // End validation



        // Instantiation
        const newInstance = new ToDo();

        if ('id' in initializationData) {
            newInstance.id = initializationData.id;
        } else {
            newInstance.id = uuid.v4();
        }

        newInstance.archived = initializationData.archived;

        if (initializationData.readableId) {
            newInstance.readableId = initializationData.readableId;
        }

        if (initializationData.description) {
            newInstance.description = initializationData.description;
        }

        if (!!initializationData.triggeringToDo) {
            newInstance.triggeringToDo = initializationData.triggeringToDo;
        }

        if (initializationData.configuration) {
            newInstance.configuration = initializationData.configuration;
        }

        if (initializationData.completedBy) {
            newInstance.completedBy = initializationData.completedBy;
        }

        newInstance.status = initializationData.status;
        newInstance.taskType = initializationData.taskType;
        newInstance.asset = initializationData.asset;
        newInstance.location = initializationData.location;
        newInstance.toDoTemplate = initializationData.toDoTemplate;
        newInstance.assignedUser = initializationData.assignedUser;
        newInstance.createdTime = initializationData.createdTime;
        newInstance.startTime = initializationData.startTime;
        newInstance.dueTime = initializationData.dueTime;
        newInstance.customAttributeValues = initializationData.customAttributeValues;
        newInstance.answers = initializationData.answers;
        newInstance.currentStateId = initializationData.currentStateId;
        newInstance.events = initializationData.events;

        return newInstance;
    }

    getHistory() {
        return this.events.filter(event => event.type === ToDoEventType.STATE).map(event => event.payload as StateSnapshot);
    }

    getTriggeredActions() {
        return this.events.filter(event => event.type === ToDoEventType.TRIGGERED_ACTION).map(event => event.payload as ActionSnapshot);
    }

    getUserActionPrompts() {
        return this.events.filter(event => event.type === ToDoEventType.USER_ACTION_PROMPT).map(event => event.payload as UserActionReport);
    }

    convertToPlainObject() {
        return {
            ...this as ToDo,
        };
    }

    getCompletedTime(states: Array<State>) {
        const history = this.getHistory()
        const lastCompletedState = history.find(snapshot => {
            const state = states.find(state => state.id === snapshot.stateId);

            if (!state) {
                throw new Error('No matching state');
            }

            if (state.status && state.status === ToDoStatus.COMPLETED) {
                return true;
            }

            return false;
        });

        if (lastCompletedState) {
            return lastCompletedState.transitionTime;
        }
    }

    getResponseTime(states: Array<State>) {
        const lastCompletedTime = this.getCompletedTime(states);

        if (!lastCompletedTime) {
            return undefined;
        }

        return moment(this.createdTime).diff(moment(lastCompletedTime));
    }

    private getDate(dateString: string|undefined) {
        if (!dateString) {
            return undefined;
        } else {
            return dateString.substring(0, 10);
        }
    }

    private getMonth(dateString: string) {
        if (!dateString) {
            return undefined;
        } else {
            return dateString.substring(0, 7);
        }
    }

    getCreatedDate() {
        return this.getDate(this.createdTime);
    }

    getCreatedMonth() {
        return this.getMonth(this.createdTime);
    }

    getStartDate() {
        return this.getDate(this.startTime);
    }

    getStartMonth() {
        return this.getMonth(this.startTime);
    }

    getDueDate() {
        return this.getDate(this.dueTime);
    }

    getDueMonth() {
        return this.getMonth(this.dueTime);
    }

    getCompletedDate(states: Array<State>) {
        const completedTime = this.getCompletedTime(states);
        return this.getDate(completedTime);
    }

    getCompletedMonth(states: Array<State>) {
        const completedTime = this.getCompletedTime(states);
        return this.getMonth(completedTime);
    }

    update(updateObject: any) {
        const { id, ...otherParams } = updateObject;

        for (const classKey of Object.keys(updateObject)) {
            if (otherParams.hasOwnProperty(classKey)) {
                this[classKey] = otherParams[classKey];
            }
        }
    }
}
