import { Injectable } from '@angular/core';
import { StorageWrapper } from 'src/shared/storage/storage-wrapper';
import Employee from 'src/shared/models/employee';
import { EMPLOYEES } from 'src/shared/mocks/employee';
import { AsyncLocalStorage } from 'src/shared/storage/storable';
import { AuthenticationService } from 'src/shared/services/authentication.service';
import { UrlService } from 'src/shared/services/url.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {
    storage: AsyncLocalStorage;
    storageWrapper: StorageWrapper<Employee>;

    constructor(
        authenticationService: AuthenticationService,
        urlService: UrlService,
        http: HttpClient
    ) {
        this.storage = new AsyncLocalStorage();
        this.storageWrapper = new StorageWrapper(
            'employee_entries',
            'employees',
            Employee.getInstanceFromObject,
            this.storage,
            authenticationService,
            urlService,
            http,
            EMPLOYEES
        );
    }

    async getEmployeeEntries(force = false) {
        return this.storageWrapper.getEntries(force);
    }

    getEmployeeEntriesObservable() {
        return this.storageWrapper.getEntriesObservable();
    }

    async addEmployee(newEmployee: Employee) {
        return this.storageWrapper.addEntry(newEmployee);
    }

    async updateEmployee(employee: Employee) {
        return this.storageWrapper.updateEntry(employee);
    }

    async deleteEmployee(deletedEmployeeId: string) {
        return this.storageWrapper.softDeleteEntry(deletedEmployeeId);
    }

    async getEmployeeById(id: string) {
        return this.storageWrapper.getEntryById(id);
    }

    getEmployeeEntryObservable(id: string) {
        return this.storageWrapper.getEntryObservable(id);
    }

    async refreshEntries() {
        return this.storageWrapper.refreshEntries();
    }

    async pushDataToServer() {
        return this.storageWrapper.pushDataToServer();
    }
}
