import CustomAttribute, { CustomAttributeType } from '../models/custom-attribute';
import AssetTemplate from '../models/asset-template';
import { DEPARTMENTS } from './department';

export const ASSET_TYPE_CUSTOM_ATTRIBUTES: Array<CustomAttribute> = [{
    id: 'd1860df0-5309-46f3-a7c6-9393640f8e1b',
    name: 'Price',
    type: CustomAttributeType.NUMBER,
    options: [],
    isRequired: true,
    isInTable: true,
}];

export const ASSET_TEMPLATES: Array<AssetTemplate> = [AssetTemplate.getInstanceFromObject({
    id: '8631bd9b-3d89-4d83-a7ad-5745540d222e',
    name: 'Asset type 1',
    department: DEPARTMENTS[0].id,
    customAttributes: ASSET_TYPE_CUSTOM_ATTRIBUTES,
})];