import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

    constructor() {}

    getBaseURL(): string {
        return window.location.hostname === 'localhost' ? 'http://localhost:8000/' : 'https://service.press9.webtrigon.com/';
    }
}
