export interface Storable {
    id: string;
    archived: boolean;

    update: (object: object) => void;
    convertToPlainObject: () => object;
}

export interface Storage {
    set: (key: string, value: any) => Promise<any>;
    get: (key: string) => Promise<any>;
}

export class AsyncLocalStorage {

    async set(key: string, value: any) {
        return window.localStorage.setItem(key, JSON.stringify(value));
    }

    async get(key: string) {
        return JSON.parse(window.localStorage.getItem(key));
    }
}

export class DummyStorage {
    storageObject = {};

    async set(key: string, value: any) {
        return this.storageObject[key] = value;
    }

    async get(key: string) {
        return this.storageObject[key];
    }
}
